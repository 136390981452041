import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isDesktop = false;

  constructor(private router: Router, private platform: Platform) {
    this.router.navigate(["home"], {skipLocationChange: true});

    this.platform.platforms().forEach(element => {
      element == 'desktop' && this.isDesktop == false ? this.isDesktop = true : '';
    });

    if(this.isDesktop && !window.location.href.includes('platform=desktop')){
      window.location.href = 'desktop.html?platform=desktop';
    }
  }
}
