import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

declare function chartComposicaoCarteira(chartType:string, composicao_carteira:Array<any>);
declare function chartPacialCarteira(parcialCarteiraData:Array<any>, chartNumber:string, sortChart:string);
declare function chartVendasCarteira(vendasCarteiraData:Array<any>, chartNumber:string, sortChart:string);
declare function chartProventos(proventosChartData:Array<any>, chartNumber:string, sortChart:string);
declare function chartAluguel(aluguelChartData:Array<any>, chartNumber:string, sortChart:string);
declare function chartImposto(impostoChartData:Array<any>, chartNumber:string);
declare function chartBalanco(balancoChartData:Array<any>, chartNumber:string);

declare var gridLinesColor;
declare var chartLabelColor;

@Injectable()
export class GlobalVars{
    appVersion = '0.0.1';

    URL_DOMAIN = 'http://www.xtremebh.com.br/';

    isToastOpen = false;
    isWeekend = false;
    isAndroidPlatform = false;

    balanco = {
      simplificado: [],
      detalhado: [],
    };
    mesesBalancosLoaded = false;

    cotacoes:any = [];
    carteira:any = [];
    isCarteiraLoaded = false;
    carteiraIsUpdating = false;

    CEIActions = [];
    CEIimportacaoDados = {
        importacaoDados: [],
    };
    CEIcpf = '';
    CEIsenha = '';
    CEIdata = '';
    CEIdataInicio = '';
    CEIdataFim = '';
    CEInextUpdate = '---';
    CEIlastUpdate = '---';
    isSyncCEI = false;
    showSyncButton = false;
    CEIStatusSync: string = 'Iniciando...';
    CEIstatusReady = false;
    CEIisReadyToImport = true;
    CEIimportHasError = false;
    CEIimportHasInfo = false;
    CEIprogressNOW = 100;
    CEItotalToImport = 0;
    isSyncCEIfrom = '';
    CEIPaginationPage:Number = 1;

    historicoResgates = {
        historico: [],
        pagination: [],
    };
    needRequestHistoricoResgates = true;

    impostoRenda = [];
    needLoadImpostoRenda = true;

    listaAtivos = [];
    listaCorretoras = [];

    mensagensApp = {
        mensagens: [],
        pagination: [],
        total: 0
    };

    mercados:any = [];
    mercados_original:any;
    mercado_categoria_original = null;
    mercadosCapa = [];
    buscaMercadoOpcoesCodigo = {
      codigo: '',
      nome: '',
    };

    needLoadExtrato = true;
    needLoadBalancoHistorico = true;
    needLoadBalancoDiario = true;
    needLoadBalancos = true;

    isPoliticaPrivacidadeAccepted = false;

    relatorio = [];
    relatorioListItems = [];

    radarAtivosList = [];
    radarAtivosNomesList = [];
    doSaveRadarAtivos = false;

    setColorChart = '';
    colorPickerFirstOpen = true;
    chart = {
      isChartParcialLoaded: false,
      isChartParcialEmpty: false,
      isChartProventosLoaded: false,
      isChartAluguelLoaded: false,
      isChartProventosEmpty: false,
      isChartAluguelEmpty: false,
      isChartResgateEmpty: false,
      isChartResgateLoaded: false,
      isChartImpostoEmpty: false,
      isChartBalancoEmpty: false,
    }
    
    timeoutLoadCotacao;
    today_date = new Date().toISOString().slice(0, 10);
    usuarioDados = [];

    config_user = [];
    appConfig = {
      chartOrientation: 'Retrato',
      chartParcialSort: 'alph',
      chartVendasSort: 'alph',
      chartProventosSort: 'alph',
      chartAluguelSort: 'alph',
      composicaoChartType: 'doughnut',
      proventosChartType: 'horizontalBar',
      aluguelChartType: 'horizontalBar',

      balancoDiarioMes: '',
      balancoDiarioAno: '',
      balancoCalendario: '',
      
      darkMode: true,

      impostoRendaCalendario: '',

      lastCorretoraSearch: '',
      lastAtivoSearch: '',

      NoticiasCanal: 'Todas',

      mercadosCapas: 'true',
      mercadosExibicao: 'Carrossel',
      mercadosTabelasFundo: 'Não',

      resgatesInicio: '',
      resgatesFim: '',
      resgatesFiltro: '',
      resgateOrdemFiltro: '',

      session: '',

      token: '',
    }
    
    isUserLogged = false;

    constructor(private http: HttpClient, private Storage: Storage, private platform: Platform, private toastController: ToastController, private alertController: AlertController){
      this.platform.platforms().forEach(element => {
       //console.log(element)
        //element == 'desktop' && this.isDesktop == false ? this.isDesktop = true : '';
      });
    }

    userNotLogged(){
        alert('not logged');
    }

    getUserAgent(string = ''){
      return this.platform.testUserAgent(string);
    }

    async openConfig(){
        return new Promise((resolve) =>{
            this.Storage.get('appConfig').then((val) => {
                if(val != null){
                    this.appConfig.darkMode = (val['darkMode'] !== null && val['darkMode'] !== '' ? val['darkMode'] : true);
                    if(this.appConfig.darkMode){
                        document.body.setAttribute('class', 'dark');
                    }else{
                        document.body.removeAttribute('class');
                    }

                    this.appConfig.token = (val['token'] != null && val['token'] != '' ? val['token'] : '');
                    this.appConfig.lastAtivoSearch = (val['lastAtivoSearch'] != null && val['lastAtivoSearch'] != '' ? val['lastAtivoSearch'] : '');
                    this.appConfig.lastCorretoraSearch = (val['lastCorretoraSearch'] != null && val['lastCorretoraSearch'] != '' ? val['lastCorretoraSearch'] : '');
                    this.appConfig.chartOrientation = (val['chartOrientation'] != null && val['chartOrientation'] != '' ? val['chartOrientation'] : 'Retrato');
                    this.appConfig.chartParcialSort = (val['chartParcialSort'] != null && val['chartParcialSort'] != '' ? val['chartParcialSort'] : 'alph');
                    this.appConfig.chartVendasSort = (val['chartVendasSort'] != null && val['chartVendasSort'] != '' ? val['chartVendasSort'] : 'alph');
                    this.appConfig.chartProventosSort = (val['chartProventosSort'] != null && val['chartProventosSort'] != '' ? val['chartProventosSort'] : 'alph');
                    this.appConfig.chartAluguelSort = (val['chartAluguelSort'] != null && val['chartAluguelSort'] != '' ? val['chartAluguelSort'] : 'alph');
                    this.appConfig.balancoDiarioMes = (val['balancoDiarioMes'] != null && val['balancoDiarioMes'] != '' ? val['balancoDiarioMes'] : '');
                    this.appConfig.balancoDiarioAno = (val['balancoDiarioAno'] != null && val['balancoDiarioAno'] != '' ? val['balancoDiarioAno'] : '');
                    this.appConfig.balancoCalendario = (val['balancoCalendario'] != null && val['balancoCalendario'] != '' ? val['balancoCalendario'] : '');
                    this.appConfig.mercadosCapas = (val['mercadosCapas'] != null && val['mercadosCapas'] != '' ? val['mercadosCapas'] : 'true');
                    this.appConfig.mercadosExibicao = (val['mercadosExibicao'] != null && val['mercadosExibicao'] != '' ? val['mercadosExibicao'] : 'Carrossel');
                    this.appConfig.mercadosTabelasFundo = (val['mercadosTabelasFundo'] != null && val['mercadosTabelasFundo'] != '' ? val['mercadosTabelasFundo'] : 'Não');
                    this.appConfig.impostoRendaCalendario = (val['impostoRendaCalendario'] != null && val['impostoRendaCalendario'] != '' ? val['impostoRendaCalendario'] : '');

                    //   this.globalVars.appConfig.passoPassoCEI = (val['passoPassoCEI'] != null && val['passoPassoCEI'] != '' ? val['passoPassoCEI'] : 'false');
                
                //   this.globalVars.appConfig.DevicePlatform = (val['DevicePlatform'] != null && val['DevicePlatform'] != '' ? val['DevicePlatform'] : this.globalVars.appConfig.SystemDevicePlatform);
                //   
                //   this.globalVars.appConfig.NoticiasCanal = (val['NoticiasCanal'] != null && val['NoticiasCanal'] != '' ? val['NoticiasCanal'] : 'Todas');
                //   this.globalVars.appConfig.extratoInicio = (val['extratoInicio'] != null && val['extratoInicio'] != '' ? val['extratoInicio'] : '');
                //   this.globalVars.appConfig.extratoFim = (val['extratoFim'] != null && val['extratoFim'] != '' ? val['extratoFim'] : '');
                
                //   this.globalVars.appConfig.resgatesInicio = (val['resgatesInicio'] != null && val['resgatesInicio'] != '' ? val['resgatesInicio'] : '');
                //   this.globalVars.appConfig.resgatesFim = (val['resgatesFim'] != null && val['resgatesFim'] != '' ? val['resgatesFim'] : '');
                //   this.globalVars.appConfig.resgatesFiltro = (val['resgatesFiltro'] != null && val['resgatesFiltro'] != '' ? val['resgatesFiltro'] : '');
                //   this.globalVars.appConfig.resgateOrdemFiltro = (val['resgateOrdemFiltro'] != null && val['resgateOrdemFiltro'] != '' ? val['resgateOrdemFiltro'] : '');     
                  
                //   this.globalVars.appConfig.composicaoChartType = (val['composicaoChartType'] != null && val['composicaoChartType'] != '' ? val['composicaoChartType'] : 'doughnut');
                //   this.globalVars.appConfig.proventosChartType = (val['proventosChartType'] != null && val['proventosChartType'] != '' ? val['proventosChartType'] : 'horizontalBar');
                //   this.globalVars.appConfig.aluguelChartType = (val['aluguelChartType'] != null && val['aluguelChartType'] != '' ? val['aluguelChartType'] : 'horizontalBar');
                //   
                  
                 
                //   this.globalVars.appConfig.panelsCarteira = (val['panelsCarteira'] != null && val['panelsCarteira'] != '' ? val['panelsCarteira'] : []);
                //  
                //   
                }

                this.setViewMode(true);
                
                resolve(true);
            });
        });
    }

    setViewMode(isInitApp = false){
      if(this.appConfig.darkMode){
        chartLabelColor = '#999';
        gridLinesColor = 'rgba(255,255,255,0.1)';
        document.body.setAttribute('class', 'dark');
      }else{
        chartLabelColor = '#222';
        gridLinesColor = 'rgba(0,0,0,0.1)';
        document.body.removeAttribute('class');
      }
      
      if(!isInitApp){
        this.saveAppConfig();
      }
    }

    saveAppConfig(){
        this.Storage.set('appConfig', this.appConfig);
    }

    async doTokenLogin(){
      const toastLogin = await this.toastController.create({
        header: 'Conectando',
        message: 'Tentando fazer login...',
        position: 'top',
        mode: 'ios',
        color: 'warning',
        duration: 15000,
        buttons: [
          {
          text: 'Fechar',
            role: 'cancel',
          }
        ]
      });
  
      (await toastLogin).present();
  
      let headers = new HttpHeaders({
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      });
  
      let postFields = {
        token: this['appConfig']['token'],
        app_version: this.appVersion,
        resgatesInicio: this.appConfig.resgatesInicio,
        resgatesFim: this.appConfig.resgatesFim,
        resgatesFiltro: this.appConfig.resgatesFiltro,
        resgateOrdemFiltro: this.appConfig.resgateOrdemFiltro,
       }
  
      this.http.post(this.URL_DOMAIN + 'index.php?route=finance_v2/conta', postFields, {headers: headers}).subscribe(
        data =>{
          this.toastController.dismiss();
          const dataResult:any = data;
  
          if(dataResult.error && dataResult.error == 'not_logged'){
            this.showAlertInfo('Erro', 'A tentativa de fazer login automático falhou!');
            return false;
          }else if(dataResult.error){
            this.showAlertInfo(dataResult.title, dataResult.error);
            return false;
          }
  
          this.userLogged(dataResult);
        },
        error => {
          setTimeout(() => {
            const alert = this.alertController.create({
              mode: 'ios',
              backdropDismiss: false,
              header: "Ocorreu um erro!",
              message: 'A tentativa de fazer login falhou! Nossos servidores podem estar com alto volume de acesso. Tente novamente mais tarde.',
              buttons: [{
                  text: 'Tentar novamente',
                  role: 'cancel',
                  handler: () => {
                    setTimeout(() => {
                      this.doTokenLogin();
                    }, 500);
                  }
                }]
            }).then(alert=> alert.present());
          }, 500);
        }
      );
    }

    userLogged(dataResult){
      return new Promise((resolve) =>{
        this.appConfig['session'] = dataResult.session;
        dataResult.token != '' ? this.appConfig['token'] = dataResult.token : '';
        this.usuarioDados = dataResult.usuario,
        this.carteira = dataResult.carteira;
        this.isCarteiraLoaded = true;
        this.config_user = dataResult.config_user;
        this.today_date = dataResult.today_date;
        this.listaAtivos = dataResult.listaAtivos;
        this.listaCorretoras = dataResult.listaCorretoras;
        this.CEIlastUpdate = dataResult.CEIDados.lastUpdate;
        this.CEInextUpdate = dataResult.CEIDados.nextUpdate;
        this.mensagensApp.total = dataResult.totalMensagens;
        this.relatorio = dataResult.relatorio;
        this.mercados = [];
        
        this.saveAppConfig();
        this.isUserLogged = true;
        this.loadCotacao();

        return resolve(true);
      });
            

        // this.hasLoggedSession = true;
      //  this.showSyncButton = false;
          //  this.CEIActions = this.dataResult.CEIActions;
          //  this.globalVars.CEIimportacaoDados = this.dataResult.CEIDados.importacao;
          //  this.globalVars.CEIcpf = this.dataResult.CEIDados.cpf;
          //  this.globalVars.CEIsenha = this.dataResult.CEIDados.senha;
          //  this.globalVars.CEIdata = this.dataResult.CEIDados.ceiNextData;
          //  this.globalVars.CEIdataInicio = this.dataResult.CEIDados.CEIdataInicio;
          //  this.globalVars.CEIdataFim = this.dataResult.CEIDados.ceiDataFim;
          //  
          //  this.globalVars.CEItotalToImport = this.dataResult.CEIDados.totalImportacao;
          //  this.globalVars.stopTogglerChange = true;
          //  this.globalVars.autoDeposito = false;
          //  
          //  
       
          //  setTimeout(() => {
          //    if(this.globalVars.config_user['autoDeposito'] == true || this.globalVars.config_user['autoDeposito'] == 'true'){
          //      this.globalVars.stopTogglerChange = true;
          //      this.globalVars.autoDeposito = true;
          //    }else{
          //      this.globalVars.stopTogglerChange = false;
          //      this.globalVars.stopInitialToggleChange = false;
          //    }
       
          //    this.renderer.addClass(loginWindow, 'loginWindowFadeOut');
          //  }, 1000);
       
          
          //  (this.globalVars.appConfig.impostoRendaCalendario == '' ? this.globalVars.appConfig.impostoRendaCalendario = this.dataResult.today_date : '');
          //  this.globalVars.needLoadBalancos = true;
          //  let loginWindow = document.getElementById('loginWindow')
       
          //  if(this.dataResult.termos_condicoes == ''){
          //    this.openModalPoliticaPrivacidade('login');
          //  }else if(this.dataResult.update_termos_condicoes != ''){
          //    this.openModalPoliticaPrivacidade('login');
          //    setTimeout(() => {
          //      this.globalVars.showAlertInfo('Atualização Termos e Condições', this.dataResult.update_termos_condicoes);
          //    }, 2000);
          //  }
       
          //  setTimeout(() => { 
          //    this.renderer.setStyle(loginWindow, 'display', 'none');
          //    if(this.dataResult.checkMesesAberto != ''){
          //      this.globalVars.showAlertInfo('Em aberto', this.dataResult.checkMesesAberto);
          //    }
          //   }, 3000);
       
          //  setTimeout(() => { this.checkVersionUpdate(); }, 5000);
           
          //  this.globalVars.CEIStatusSync = '---';
          //  this.globalVars.CEIisReadyToImport = false;
       
          //  if(this.globalVars.CEIimportacaoDados['hasImportError'] || this.globalVars.CEIimportacaoDados['hasImportInfo']){
          //    this.globalVars.CEIisReadyToImport = true;
          //    if(this.globalVars.CEIimportacaoDados['hasImportError']){
          //      this.globalVars.CEIimportHasError = true;
          //      this.globalVars.CEIStatusSync = 'O processo de importação encontrou erros! Clique EDITAR para visualizar.'
          //    }else{
          //      this.globalVars.CEIimportHasInfo = true;
          //      this.globalVars.CEIStatusSync = 'O processo de importação encontrou observações! Clique EDITAR para visualizar.'
          //    }
          //  }else if(this.globalVars.CEItotalToImport > 0){
          //    this.globalVars.CEIisReadyToImport = true;
          //    this.globalVars.CEIStatusSync = '(' + this.globalVars.CEItotalToImport + ' transações) Pronto para atualizar sua carteira!';
          //  }
       
          //  (this.globalVars.CEIActions['debugSyncNow'] != '' ? this.globalVars.CEInextUpdate = 'Hoje' : '');
               
          //  if(this.globalVars.CEIcpf != '' && this.globalVars.CEInextUpdate == 'Hoje'){
          //    this.globalVars.showSyncButton = true;
          //  }
       
          //  this.globalVars.total_imposto_pago = this.globalVars.relatorio['imposto_chart']['total_imposto_pago'];
           
    }

    keepSessionAlive(){
      this.http.get(this.URL_DOMAIN + 'index.php?route=finance_v2/finance_v2/keepSessionAlive&session='+this.appConfig.session).subscribe(
        data =>{
          this.appConfig.session = data['session'];
        });
    }

    loadCotacao(){
      if(this.appConfig.session == '' || this.carteira.length == 0){
        this.relatorio['patrimonio_lucro_prejuizo_spinner'] = false;
        return false; //usuario fez logout ou nao existe ativo na carteira para puxar cotacao
      }
  
      this.carteiraIsUpdating = true;
  
      if(this.isWeekend){
        //fake update... final de semana... como já foi carregado a primeira cotacao, nao precisa mais
        this.relatorio['patrimonio_lucro_prejuizo_spinner'] = false;
        setTimeout(() => {
          this.carteiraIsUpdating = false;
        }, 7000);
  
        //vamos apenas manter a sessao ativa
        this.keepSessionAlive();
        return false;
      }
  
      clearTimeout(this.timeoutLoadCotacao);
  
      this.http.get(this.URL_DOMAIN + 'index.php?route=finance_v2/cotacoes&session='+this.appConfig.session).subscribe(
        data =>{
          const dataResult:any = data;
          if(!dataResult){
            this.showAlertInfo('Fora do ar', 'Erro ao tentar accessar a cotação dos ativos! É bem provável que o sistema da B3 esteja fora do ar ou com um grande volume de acesso.'); 
            return false;
          }else if(dataResult.error && dataResult.error == 'not_logged'){
            this.doTokenLogin();
            //this.hasLoggedSession.next(false);
            return false;
          }else if(dataResult.error){
            this.showAlertInfo(dataResult.title, dataResult.error); 
            return false;           
          }
  
          this.mensagensApp.total = dataResult.totalMensagens;
          this.cotacoes = dataResult.cotacoes;
          this.isWeekend = dataResult.isWeekend;
          this.relatorio['carteira_parcial_chart'] = dataResult['carteira_parcial_chart'];
          
          
          //chartPacialCarteira(this.relatorio['carteira_parcial_chart'], 'one', this.appConfig['chartParcialSort']);
      
          //       this.chart.isChartParcialLoaded = true; 
          this.relatorio['carteira_valor_investido'] = dataResult['carteira_valor_investido'];
          this.relatorio['carteira_valor_atual'] = dataResult['carteira_valor_atual'];
          this.relatorio['carteira_lucro_prejuizo'] = dataResult['carteira_lucro_prejuizo'];
          this.relatorio['carteira_lucro_prejuizo_porcentagem'] = dataResult['carteira_lucro_prejuizo_porcentagem'];
          this.relatorio['carteira_lucro_prejuizo_status'] = dataResult['carteira_lucro_prejuizo_status'];
          this.relatorio['carteira_impacto_no_patrimonio'] = dataResult['carteira_impacto_no_patrimonio'];
          this.relatorio['carteira_impacto_no_patrimonio_status'] = dataResult['carteira_impacto_no_patrimonio_status'];
          this.relatorio['patrimonio_total'] = dataResult['patrimonio_total'];
          this.relatorio['patrimonio_lucro_prejuizo'] = dataResult['patrimonio_lucro_prejuizo'];
          this.relatorio['patrimonio_lucro_prejuizo_porcentagem'] = dataResult['patrimonio_lucro_prejuizo_porcentagem'];
          this.relatorio['patrimonio_lucro_prejuizo_status'] = dataResult['patrimonio_lucro_prejuizo_status'];
          this.relatorio['patrimonio_lucro_prejuizo_spinner'] = dataResult['patrimonio_lucro_prejuizo_spinner'];
          this.relatorio['balanco_chart'] = dataResult['balanco_chart'];
            
      //       chartBalanco(this.relatorio['balanco_chart'], 'one');
            
          this.cotacoes.forEach(cotacao => {
            this.carteira.forEach((ativo, index) => {
              if(cotacao['ativo_id'] == ativo['ativo_id']){
                this.carteira[index]['data_cotacao'] = cotacao['data_cotacao'];
                this.carteira[index]['cotacao'] = cotacao['cotacao'];
                this.carteira[index]['min'] = cotacao['min'];
                this.carteira[index]['max'] = cotacao['max'];
                this.carteira[index]['volume'] = cotacao['volume'];
                this.carteira[index]['variacao'] = cotacao['variacao'];
                this.carteira[index]['cotacao_status'] = cotacao['cotacao_status'];
                this.carteira[index]['variacao_valor'] = cotacao['variacao_valor'];
                this.carteira[index]['valor_atual'] = cotacao['valor_atual'];
                this.carteira[index]['lucro_prejuizo'] = cotacao['lucro_prejuizo'];
                this.carteira[index]['lucro_prejuizo_status'] = cotacao['lucro_prejuizo_status'];
                this.carteira[index]['lucro_prejuizo_porcentagem'] = cotacao['lucro_prejuizo_porcentagem'];
                this.carteira[index]['status'] = cotacao['status'];
                this.carteira[index]['principal'] = cotacao['principal'];
              }
            });
          });
  
          this.carteiraIsUpdating = false;
  
          //ligar o timer para atualizar a cada 60 segundos
          this.timeoutLoadCotacao = setTimeout(() => {
            if(this.carteiraIsUpdating == false){
              this.loadCotacao();
            }
          }, 60000);
        },
        error => {
          this.carteiraIsUpdating = false;
          setTimeout(() => {
            this.loadCotacao();
          }, 10000);//erro, vamos voltar a chamar em 10 segundos
        }
      );
    }

    buildChartProventos(){
      chartProventos(this.relatorio['proventos_chart'], 'one', this.appConfig['chartProventosSort']);
    }
    buildChartAluguel(){
      chartAluguel(this.relatorio['aluguel_chart'], 'one', this.appConfig['chartAluguelSort']);
    }
    buildChartPacialCarteira(){
      chartPacialCarteira(this.relatorio['carteira_parcial_chart'], 'one', this.appConfig['chartParcialSort']);
    }
    buildChartVendasCarteira(){
      chartVendasCarteira(this.relatorio['vendas_carteira_chart'], 'one', this.appConfig['chartVendasSort']);
    }

    buildCharts(){
      if(this.carteira.length > 0){
        this.chart.isChartParcialEmpty = false;
        chartComposicaoCarteira(this.appConfig.composicaoChartType, this.relatorio['composicao_carteira_chart']);
        
        if(this.relatorio['carteira_parcial_chart'] && this.relatorio['carteira_parcial_chart']['valor'].length > 0){
          chartPacialCarteira(this.relatorio['carteira_parcial_chart'], 'one', this.appConfig['chartParcialSort']);
        }
      }else{
        this.chart.isChartParcialEmpty = true;
      }
      

      if(this.relatorio['vendas_carteira_chart']['valor'].length > 0){
        this.chart.isChartResgateEmpty = false;
        chartVendasCarteira(this.relatorio['vendas_carteira_chart'], 'one', this.appConfig['chartVendasSort']);
        this.chart.isChartResgateLoaded = true;
      }else{
        document.getElementById('div-chart-resgate-one').innerHTML = '';
        document.getElementById('div-chart-resgate-two').innerHTML = '';
        this.chart.isChartResgateEmpty = true;
      }  

      if(this.relatorio['proventos_chart']['valor'].length > 0){
        this.chart.isChartProventosEmpty = false;
        chartProventos(this.relatorio['proventos_chart'], 'one', this.appConfig['chartProventosSort']);
      }else{
        this.chart.isChartProventosEmpty = true;
        document.getElementById('div-chart-proventos-one').innerHTML = '';
        document.getElementById('div-chart-proventos-two').innerHTML = '';
      }
      this.chart.isChartProventosLoaded  = true;
  

      if(this.relatorio['aluguel_chart']['valor'].length > 0){
        this.chart.isChartAluguelEmpty = false;
        chartAluguel(this.relatorio['aluguel_chart'], 'one', this.appConfig['chartAluguelSort']);
      }else{
        this.chart.isChartAluguelEmpty = true;
        document.getElementById('div-chart-aluguel-one').innerHTML = '';
        document.getElementById('div-chart-aluguel-two').innerHTML = '';
      }
      this.chart.isChartAluguelLoaded  = true;
  
  
      if(this.relatorio['imposto_chart']['months'].length > 0){
        this.chart.isChartImpostoEmpty = false;
        chartImposto(this.relatorio['imposto_chart'], 'one');
      }else{
        this.chart.isChartImpostoEmpty = true;
        document.getElementById('div-chart-imposto-one').innerHTML = '';
        document.getElementById('div-chart-imposto-two').innerHTML = '';
      }
  
  
      if(this.relatorio['balanco_chart']['months'].length > 0){
        this.chart.isChartBalancoEmpty = false;
        chartBalanco(this.relatorio['balanco_chart'], 'one');
      }else{
        this.chart.isChartBalancoEmpty = true;
        document.getElementById('div-chart-balanco-one').innerHTML = '';
        document.getElementById('div-chart-balanco-two').innerHTML = '';
      }
    }

    checkImportPendente(){
        if(this.CEIimportacaoDados.importacaoDados.length > 0){
          this.showAlertInfo('Não pode', 'Existem ativos aguardando para serem importados do CEI na sua carteira! Ao importar, o app verifica os ativos que você possue na carteira para escolher a melhor forma de adicioná-los. Ao adicionar, atualizar ou remover um ativo manualmente, esta verificação da carteira fica comprometida. Remova a importação pendente primeiro.');
          return false;
        }
  
        return true;
    }

    async showToastInfo(title, text, color = ''){
        let delayShow = (this.isToastOpen ? 700 : 0);
        this.closeToastInfo();
    
        const toastInfo = await this.toastController.create({
          header: title,
          message: text,
          position: 'top',
          mode: 'ios',
          color: color,
          buttons: [
            {
              text: 'Fechar',
              role: 'cancel',
              handler: () => {
                this.isToastOpen = false;
              }
            }
          ]
        });
    
        setTimeout(() => {
          toastInfo.present();
        }, delayShow);
        
        this.isToastOpen = true;
    }
    closeToastInfo(){
        if(this.isToastOpen){
          this.isToastOpen = false;
          this.toastController.dismiss();
        }
    }

    async showAlertInfo(header, message){
        const alertInfo = this.alertController.create({
          mode: 'ios',
          backdropDismiss: false,
          header: header,
          message: message,
          buttons: [{
              text: 'OK',
              role: 'cancel',
            }]
        }).then(alertInfo=> alertInfo.present());    
      }
}