import { Component, Renderer2, ViewChild, Input } from '@angular/core';
import { ModalController, ActionSheetController, LoadingController, IonContent, AlertController, IonDatetime } from '@ionic/angular';
import { GlobalVars } from '../service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProventosAluguelPage } from '../proventos-aluguel/proventos-aluguel.page';
import { AtivoExtraActions } from '../service_ExtraActions';

declare let $;

@Component({
  selector: 'app-resgate',
  templateUrl: './resgate.page.html',
  styleUrls: ['./resgate.page.scss'],
})

export class ResgatePage {
  private unlistnerIonAlertWillPresent: () => void;
  private unlistnerIonActionSheetDidDismiss: () => void;
  private unlistnerIonActionSheetWillPresent: () => void;
  private unlistnerIonLoadingDidDismiss: () => void;

  isGettingResgates = false;
  isCancellingResgate = false;
  isActionSheetOpen = false;

  resgateHistoricoHasLoaded = false;

  oldFiltro = '';
  customResgatesFiltro = '';
  customResgateOrdemFiltro = '';
  customResgatesInicio = '';
  customResgatesFim = '';

  ativoEditIndex = -1;

  getPage:number = 1;
  
  update_resgates_button:any;

  night_mode = '';

  qtd_daytrade_info = '';

  filterDateAction = '';
  filterDateStart = '';
  filterDateEnd = '';
  currentActiveFilter = '';

  needUpdateCarteira = false;

  @ViewChild('contentResgates') contentResgates: IonContent;
  @ViewChild('ionDateTime') ionDateTime: IonDatetime

  @ViewChild('ionDateTimeFilter') ionDateTimeFilter: IonDatetime

  constructor(public globalVars: GlobalVars, public ativoExtraActions: AtivoExtraActions, private proventosAluguelPage: ProventosAluguelPage, private http: HttpClient, private renderer: Renderer2, private loadingController: LoadingController, private alertController: AlertController, private actionSheetController: ActionSheetController, private modalCtrl: ModalController) { }

  ionViewDidEnter() {    
    if(this.globalVars.needRequestHistoricoResgates){
      this.globalVars.needRequestHistoricoResgates = false;
      this.getHistoricoResgates();
    }else{
      this.resgateHistoricoHasLoaded = true;
    }

    if(this.globalVars.historicoResgates['pagination']['pagination_info']){
      this.getPage = this.globalVars.historicoResgates['pagination']['pagination_info']['active'];
    }

    this.currentActiveFilter = (this.globalVars.appConfig.resgatesFiltro != '' ? '(' + this.globalVars.appConfig.resgatesFiltro + ')' : '');
  }

  logoAtivoLoaded(index){
    this.globalVars.historicoResgates['historico'][index]['loaded'] = true;
    setTimeout(() => {
      if(this.globalVars.historicoResgates['historico'][index]){
        this.globalVars.historicoResgates['historico'][index]['showLogo'] = true;
      }
    }, 500);
  }

  async openFiltro(action = ''){
    if(this.isActionSheetOpen){
      return false;
    }

    let buttons = [{
      text: 'Fechar',
      handler: () => {
      }
    }];

    if(action == ''){
      buttons.push({
        text: 'Limpar filtro',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => {
            this.oldFiltro = this.globalVars.appConfig.resgatesFiltro;
            this.globalVars.appConfig.resgatesFiltro = '';
            this.globalVars.appConfig.resgateOrdemFiltro = '';
            this.globalVars.appConfig.resgatesInicio = '';
            this.globalVars.appConfig.resgatesFim = '';
            this.currentActiveFilter = '';
            this.getHistoricoResgates();
          }, 500);
          return false;
        }
      },{
          text: 'Por data do resgate',
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => {
              this.filterByDate();
            }, 500);
            return false;
          }
      });
    }else if(action == 'orderFilter'){
      buttons.push({
        text: 'Recentes',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => {
            this.globalVars.appConfig.resgateOrdemFiltro = 'DESC';
            $('#filter_date_ordem').val(this.globalVars.appConfig.resgateOrdemFiltro.split('ASC').join('Antigos').split('DESC').join('Recentes'));
          }, 500);
          return false;
        }
      },{
          text: 'Antigos',
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => {
              this.globalVars.appConfig.resgateOrdemFiltro = 'ASC';
              $('#filter_date_ordem').val(this.globalVars.appConfig.resgateOrdemFiltro.split('ASC').join('Antigos').split('DESC').join('Recentes'));
            }, 500);
            return false;
          }
      });
    }

    if(action == '' || action == 'byDate'){
      for(let i = 0; i < this.globalVars.historicoResgates['empresas'].length; i++){      
        let button = {
          text: this.globalVars.historicoResgates['empresas'][i],
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => {
                this.oldFiltro = this.globalVars.appConfig.resgatesFiltro;
                this.globalVars.appConfig.resgatesFiltro = this.globalVars.historicoResgates['empresas'][i];
                if(action == ''){
                  this.globalVars.appConfig.resgateOrdemFiltro = '';
                  this.globalVars.appConfig.resgatesInicio = '';
                  this.globalVars.appConfig.resgatesFim = '';
                  this.getHistoricoResgates();
                }else{
                  $('#filter_date_ativo').val(this.globalVars.appConfig.resgatesFiltro);
                }
            }, 500);
            return false;
          }
          
        }
        buttons.push(button);
      }
    }
  
    const actionSheetFiltro = this.actionSheetController.create({
      header: (action == 'orderFilter' ? 'Escolha a ordem para filtrar' : 'Escolha o ativo para filtrar'),
      mode: 'md',
      buttons: buttons,
    });

    this.isActionSheetOpen = true;
  
    this.unlistnerIonActionSheetDidDismiss = this.renderer.listen((await actionSheetFiltro), 'ionActionSheetDidDismiss', () => {
      this.isActionSheetOpen = false;
      this.unlistnerIonActionSheetDidDismiss();
    });
  
    (await actionSheetFiltro).present();
  }


  async filterByDate(){
    const alertFilterByDate = this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Resgates',
      message: 'Filtrar por data',
      inputs: [{
        id: 'filter_start_date',
        type: 'text',
        value: this.globalVars.appConfig.resgatesInicio,
        placeholder: 'Data início'
      },{
        id: 'filter_end_date',
        type: 'text',
        value: this.globalVars.appConfig.resgatesFim,
        placeholder: 'Data fim'
      },{
        id: 'filter_date_ativo',
        type: 'text',
        value: this.globalVars.appConfig.resgatesFiltro,
        placeholder: 'Ativo'
      },{
        id: 'filter_date_ordem',
        type: 'text',
        value: this.globalVars.appConfig.resgateOrdemFiltro.split('ASC').join('Antigos').split('DESC').join('Recentes'),
        placeholder: 'Ordem'
      }],
      buttons: [{
          text: 'Cancelar',
          role: 'cancel',
      },{
          text :'Atualizar',
          handler: (data) => {
            this.getPage = 1;
            this.getHistoricoResgates();
          }
        }]
    });

    this.unlistnerIonAlertWillPresent = this.renderer.listen((await alertFilterByDate),'ionAlertWillPresent', () => {
      this.unlistnerIonAlertWillPresent();
      let el_input_data_inicio = document.getElementById('filter_start_date');
      this.renderer.setAttribute(el_input_data_inicio, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_input_data_inicio, 'focus', () => { this.filterDateAction = 'start'; this.openIonDateTimeFilter(); });
      this.renderer.listen(el_input_data_inicio, 'click', () => { this.filterDateAction = 'start'; this.openIonDateTimeFilter(); });

      let el_input_data_fim = document.getElementById('filter_end_date');
      this.renderer.setAttribute(el_input_data_fim, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_input_data_fim, 'focus', () => { this.filterDateAction = 'end'; this.openIonDateTimeFilter(); });
      this.renderer.listen(el_input_data_fim, 'click', () => { this.filterDateAction = 'end'; this.openIonDateTimeFilter(); });
    
      let el_filter_date_ativo = document.getElementById('filter_date_ativo');
      this.renderer.setAttribute(el_filter_date_ativo, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_filter_date_ativo, 'focus', () => { this.openFiltro('byDate'); });
      this.renderer.listen(el_filter_date_ativo, 'click', () => { this.openFiltro('byDate'); });

      let el_filter_date_ordem = document.getElementById('filter_date_ordem');
      this.renderer.setAttribute(el_filter_date_ordem, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_filter_date_ordem, 'focus', () => { this.openFiltro('orderFilter'); });
      this.renderer.listen(el_filter_date_ordem, 'click', () => { this.openFiltro('orderFilter'); });
    });

    (await alertFilterByDate).present();
  }

  openIonDateTimeFilter(){
    let tempArray;
    let newDate;
  
    if(this.filterDateAction == 'start'){
      tempArray = this.globalVars.appConfig.resgatesInicio.split('/');
      newDate = tempArray[2] + '-' + tempArray[1] + '-' +  tempArray[0];
      this.ionDateTimeFilter.value = (tempArray.length > 1 ? newDate : '');
    }else{
      tempArray = this.globalVars.appConfig.resgatesFim.split('/');
      newDate = tempArray[2] + '-' + tempArray[1] + '-' +  tempArray[0];
      this.ionDateTimeFilter.value = (tempArray.length > 1 ? newDate : '');
    }
    
    this.ionDateTimeFilter.open();
  }

  ionDateTimeFilterChange(button){
    let newDate = button.target.value;

    let tempArray;

    if(newDate.indexOf('T') > -1){
      tempArray = newDate.split('T');
      newDate = tempArray[0];
    }    
  
    tempArray = newDate.split('-');
    newDate = tempArray[2] + '/' + tempArray[1] + '/' +  tempArray[0];
    
    if(this.filterDateAction == 'start'){
      $('#filter_start_date').val((tempArray.length > 1 ? newDate : ''));
      this.globalVars.appConfig.resgatesInicio = (tempArray.length > 1 ? newDate : '');
    }else{
      $('#filter_end_date').val((tempArray.length > 1 ? newDate : ''));
      this.globalVars.appConfig.resgatesFim = (tempArray.length > 1 ? newDate : '');
    }
  }

  async getHistoricoResgates(){
    this.isGettingResgates = true;
    this.resgateHistoricoHasLoaded = false;
    (this.getPage == 0 ? this.getPage = 1 : '');

    if(this.globalVars.appConfig.resgatesFiltro != ''){
      this.currentActiveFilter = '(' + this.globalVars.appConfig.resgatesFiltro + ')';
    }else if(this.globalVars.appConfig.resgateOrdemFiltro != '' || this.globalVars.appConfig.resgatesInicio != '' || this.globalVars.appConfig.resgatesFim != ''){
      this.currentActiveFilter = '(Data)';
    }

    const resgatesFiltro = (this.customResgatesFiltro == '' ? this.globalVars.appConfig.resgatesFiltro : this.customResgatesFiltro);
    const resgateOrdemFiltro = (this.customResgateOrdemFiltro == '' ? this.globalVars.appConfig.resgateOrdemFiltro : this.customResgateOrdemFiltro);
    const resgatesInicio = (this.customResgatesInicio == '' ? this.globalVars.appConfig.resgatesInicio : this.customResgatesInicio);
    const resgatesFim = (this.customResgatesFim  == '' ? this.globalVars.appConfig.resgatesFim : this.customResgatesFim);
    
    this.http.get('https://instabov.resgates.xtremebh.com.br/index.php?route=finance_v2/resgate&resgatesFiltro=' + resgatesFiltro + '&resgateOrdemFiltro=' + resgateOrdemFiltro + '&resgatesInicio=' + resgatesInicio + '&resgatesFim=' + resgatesFim + '&page=' + this.getPage + '&session=' + this.globalVars.appConfig.session).subscribe(
      data =>{
         if(!this.isGettingResgates){
           return false;
         }

         this.isGettingResgates = false;
         this.resgateHistoricoHasLoaded = true;

         const dataResult:any = data;

         if(dataResult.error && dataResult.error == 'not_logged'){
          this.globalVars.showAlertInfo('Erro', 'not_logged resgate modal')
          return false;
        }else if(dataResult.error){
          this.globalVars.showAlertInfo(dataResult.title, dataResult.error); 
          return false;           
        }
        
        this.globalVars.historicoResgates = dataResult.historicoResgates;

        //usamos para historico solicitados a partir dos graficos... temos sempre que resetar aqui
        //this.globalVars.customResgatesInicio = '';
        //this.globalVars.customResgatesFim = '';
        //this.globalVars.customResgatesFiltro = '';
        //this.globalVars.customResgateOrdemFiltro = '';
            
        this.contentResgates.scrollToTop(500);

        this.globalVars.saveAppConfig();
      },
      error => {
        this.isGettingResgates = false;
        this.resgateHistoricoHasLoaded = true;
      }
    );
  }

  getPaginationVendas(page){
    if(this.getPage != page){
      this.getPage = page;
      this.getHistoricoResgates();
    }
  }

  
  async getResgateOptions(index){
    if(this.isActionSheetOpen){
      return false;
    }

    const actionSheetOptions = this.actionSheetController.create({
      header: 'Ações para resgate ' + this.globalVars.historicoResgates['historico'][index]['codigo'],
      mode: 'md',
      buttons: [{
        text: 'Proventos & Aluguel',
		    cssClass: 'icon_proventos',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => {
            if(this.globalVars.historicoResgates['historico'][index]['tipo_operacao'] == 'Vendido' || this.globalVars.historicoResgates['historico'][index]['tipo_operacao'] == 'Opção'){
              this.globalVars.showAlertInfo('Não pode', 'Você não recebe proventos e/ou aluguel referente a operações short ou opção!');
            }else{
              this.proventosAluguelPage.proventosAluguel(index, 'resgate');
            }
          }, 500);
          return false;
        }
      },{
          text: 'Cancelar Resgate',
          cssClass: 'icon_excluir',
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => { this.cancelarResgate(index); }, 500);
            return false;
          }
        }
      ],
    });

    this.isActionSheetOpen = true;

    this.unlistnerIonActionSheetWillPresent = this.renderer.listen((await actionSheetOptions), 'ionActionSheetWillPresent', () => {    
      $('.icon_proventos span').html('<i class="fas fa-piggy-bank icon_ativo_options"></i> Proventos & Aluguel');
      $('.icon_excluir span').html('<i class="fas fa-reply icon_ativo_options"></i> Cancelar Resgate');
      this.unlistnerIonActionSheetWillPresent();
    });

    this.unlistnerIonActionSheetDidDismiss = this.renderer.listen((await actionSheetOptions), 'ionActionSheetDidDismiss', () => {
      this.isActionSheetOpen = false;
      this.unlistnerIonActionSheetDidDismiss();
    });

    (await actionSheetOptions).present();
  }


  cancelarResgate(index){
    if(!this.globalVars.checkImportPendente()){
      return false;
    }

    let completSubHeader =  '';
    if(this.globalVars.historicoResgates['historico'][index]['short_in_ativo_obs'] != ''){
      completSubHeader = 'Este resgate faz parte de uma operação short daytrade. Este tipo de operação acontece automaticamente quando você vende e recompra um ativo na mesma data. Você talvez precise cancelar também a operação que originou este shortdaytrade.';
    }


    this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Cancelar?',
      subHeader: completSubHeader + ' Deseja mesmo cancelar o resgate ' + this.globalVars.historicoResgates['historico'][index]['codigo'] + '?',
      message: 'O ativo irá retornar para sua carteira.',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      },{
        text: 'CONTINUAR',
        handler: () => {
         this.ConfirmCancelaResgate(index);
        }
      }]
    }).then(alertExcluir=> alertExcluir.present());
  }

  async ConfirmCancelaResgate(index){
    this.isCancellingResgate = true;

    const loading = this.loadingController.create({
      message: 'Cancelando resgate... por favor aguarde.',
      duration: 15000
    });

    this.unlistnerIonLoadingDidDismiss = this.renderer.listen((await loading), 'ionLoadingDidDismiss', () => { 
      this.unlistnerIonLoadingDidDismiss();
      if(this.isCancellingResgate){
        this.isCancellingResgate = false;
        this.globalVars.showAlertInfo('Tempo esgotado', 'A tentativa de cancelar o resgate falhou! Verifique se tem uma conexão com a internet e tente novamente.');
      }
    });

    (await loading).present();

    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let postFields = {
      resgate_id: this.globalVars.historicoResgates['historico'][index]['id'],
     }

     this.http.post('https://instabov.resgates.xtremebh.com.br/index.php?route=finance_v2/resgate/cancelaResgate&resgatesFiltro=' + this.globalVars.appConfig.resgatesFiltro + '&resgateOrdemFiltro=' + this.globalVars.appConfig.resgateOrdemFiltro + '&resgatesInicio=' + this.globalVars.appConfig.resgatesInicio + '&resgatesFim=' + this.globalVars.appConfig.resgatesFim + '&session='+this.globalVars.appConfig.session, postFields, {headers: headers}).subscribe(
      data =>{
        if(!this.isCancellingResgate){
          return false;
        }

        this.isCancellingResgate = false;
        this.loadingController.dismiss();
        
        const dataResult:any = data;

        if(dataResult.error && dataResult.error == 'not_logged'){
          this.globalVars.showAlertInfo('Erro', 'not_logged cancelar resgate');
          return false;
        }else if(dataResult.error){
          this.globalVars.showAlertInfo(dataResult.title, dataResult.error);
          return false;
        }
        requestAnimationFrame(() => {
          this.globalVars.carteira = dataResult['carteira'];
          this.globalVars.config_user = dataResult['config_user'];
          this.globalVars.relatorio = dataResult['relatorio'];
          this.globalVars.historicoResgates = dataResult['historicoResgates'];
          this.globalVars.needLoadExtrato = true;
          this.globalVars.needLoadImpostoRenda = true;
          this.globalVars.needLoadBalancoHistorico = true;
          this.globalVars.needLoadBalancoDiario = true;
          this.globalVars.CEIdata = dataResult['ceiNextData'];
          this.globalVars.CEIlastUpdate = dataResult['lastUpdate'];
          this.globalVars.CEInextUpdate = dataResult['nextUpdate'];
          
          //nao vamos mais fazer o loadCarteira sempre que um ativo e removido.
          //vamos deixar para fazer quando a janela fecha, pois se remover mais de um, faz apenas 1 chamada no servidor
          //this.globalVars.loadCarteira();
          this.needUpdateCarteira = true;
        });
      },
      error => {
        this.isCancellingResgate = false;
        this.loadingController.dismiss();
      }
    );
  }




  closeResgates(){
    this.modalCtrl.dismiss().then((val) => {
      if(this.needUpdateCarteira){
        this.globalVars.needLoadExtrato = true;
        this.globalVars.needLoadImpostoRenda = true;
        this.globalVars.needLoadBalancoHistorico = true;
        this.globalVars.needLoadBalancoDiario = true;
        this.globalVars.needRequestHistoricoResgates = true;
        this.globalVars.isWeekend = false;
        this.globalVars.loadCotacao();
      }
    });
  }

  showInfoQtdDaytrade(index){
    this.qtd_daytrade_info = this.globalVars.historicoResgates['historico'][index]['qtd_daytrade'];
    this.showInfo('qtd_venda_daytrade');
  }


  async showInfo(action){
    let info = '';

    switch(action){
      case 'corretagem_compra_zero' :
      info = 'Como foi um resgate parcial e o ativo ainda encontra-se na sua carteira, a corretagem de compra é inserida no valor investido ao zerar sua posição.';
      break;
      
      case 'qtd_venda_daytrade' :
      info = this.qtd_daytrade_info;
      break;

      case 'lucro_proventos' :
      info = 'Valor já incluído em <b>Lucro/Prejuízo Final</b>.';
      break;

      case 'lucro_aluguel' :
      info = 'Valor já incluído em <b>Lucro/Prejuízo Final</b>.';
      break;

      case 'imposto_day_trade' :
      info = 'Este é o valor do IR (20%) a pagar, caso a operação de compra e venda tenha ocorrido no mesmo dia. Note que este valor, NÃO é subtraído de lucro/prejuízo final.';
      break;

      case 'imposto_retido' :
      info = 'Imposto retido na fonte, conhecido como "dedo duro". Para operações em swing trade, a porcentagem é de 0,00005 sobre o valor total da venda, mesmo que ocorra prejuízo. Para operações daytrade, esta porcentagem aumenta para 1%.<br /><br />Em daytrade, o valor sempre ficará retido pela corretora. Já para operações normais, se o valor total de I.R.R.F no mês da venda for superior a R$1,00, o valor então passa a ficar retido na fonte. Desta forma, o aplicativo irá subtrair o valor em <b>Lucro/Prejuízo Final</b>.';
      break;

      case 'imposto_retido_resgate' :
      info = 'O Imposto de Renda Retido na Fonte, quando houver, será calculado automaticamente pelo aplicativo.';
      break;

      case 'imposto_swing_trade' :
      info = 'Este é o valor do IR (15%) a pagar, caso tenha feito vendas superior a R$20.000,00 no mês atual ou, tenha negociado OPÇÕES. Se este valor estiver em R$0,00, indica que ainda não atingiu o limite no mês. Note que este valor, NÃO é subtraído de lucro/prejuízo final. Consulte o extrato de imposto em <b>Relatório Carteira</b>.';
      break;

      case 'patrimonio_na_data' :
      info = 'Valor total de aportes(depósitos) efetuados para investimento. O aplicativo utiliza este valor para calcular o impacto dos seus investimentos no seu patrimônio investido.';
      break;
    }

    this.globalVars.showToastInfo('INFORMAÇÃO', info, 'warning');
  }


  showAtivoObservacao(index){
    if(this.globalVars.historicoResgates['historico'][index]['observacoes'] != '' && this.globalVars.historicoResgates['historico'][index]['short_in_ativo_obs'] != ''){
      //aqui temos os 2, vamos abrir um actionSheet
      this.observacaoActionSheet(index);
    }else if(this.globalVars.historicoResgates['historico'][index]['observacoes'] != ''){
      this.showToastInfo(index, 'obs');
    }else if(this.globalVars.historicoResgates['historico'][index]['short_in_ativo_obs'] != ''){
      this.showToastInfo(index, '');
    }
  }

  async observacaoActionSheet(index){
    if(this.isActionSheetOpen){
      return false;
    }

    const actionSheetObservacao = this.actionSheetController.create({
      header: 'Escolha uma opção',
      mode: 'md',
      buttons: [{
        text: 'Observação',
        handler: () => {
          setTimeout(() => { this.showToastInfo(index, 'obs'); }, 400);
          setTimeout(() => { this.actionSheetController.dismiss(); }, 500);
          return false;
        }
      },{
        text: 'Shortdaytrade info',
        handler: () => {
          setTimeout(() => { this.showToastInfo(index, ''); }, 400);
          setTimeout(() => { this.actionSheetController.dismiss(); }, 500);
          return false;
        }
      }],
    });

    this.isActionSheetOpen = true;

    this.unlistnerIonActionSheetDidDismiss = this.renderer.listen((await actionSheetObservacao), 'ionActionSheetDidDismiss', () => {
      this.isActionSheetOpen = false;
      this.unlistnerIonActionSheetDidDismiss();
    });

    (await actionSheetObservacao).present();
  }

  async showToastInfo(index, action){
    const header = 'INFORMAÇÃO ' + this.globalVars.historicoResgates['historico'][index]['codigo'];
    const message = (action == 'obs' ? this.globalVars.historicoResgates['historico'][index]['observacoes'] : this.globalVars.historicoResgates['historico'][index]['short_in_ativo_obs'])

    this.globalVars.showToastInfo(header, message, 'warning');
  }
}