<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeProventosAluguelModal()">Voltar</ion-button>
    </ion-buttons>
    <ion-title class="c-ion-title">Proventos & Aluguel</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exitProventosAluguel()">Fechar</ion-button>
    </ion-buttons> 
   </ion-toolbar>
 </ion-header>

 <ion-content #contentProventosAluguel [fullscreen]="true">

  <table class="table table-striped table-hover" [ngClass]="{'table-dark':globalVars.appConfig.darkMode}">
    <thead>
        <tr class="text-center table-bordered">
            <td class="text-center table-bordered" valign="middle"><b>Tipo</b></td>
            <td width="24%" class="text-center table-bordered" valign="middle"><b>Data</b></td>
            <td width="24%" class="text-center table-bordered" valign="middle"><b>Valor</b></td>
            <td width="16%" class="text-center table-bordered" valign="middle"><b>Ação</b></td>
        </tr>
    </thead>
    
    <tbody>
      <tr class="table-bordered" *ngIf="proventosAluguelList['proventosAluguelList'].length == 0">
        <td colspan="4" class="text-center table-bordered">Nenhum provento e/ou aluguel adicionado ainda!</td>
      </tr>

      <tr *ngFor="let proventoAluguel of proventosAluguelList['proventosAluguelList']; let i = index">
        <td class="text-center table-bordered" style="vertical-align:middle">{{proventoAluguel["tipo"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle">{{proventoAluguel["data"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle">{{proventoAluguel["valor"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle"><button type="button" class="btn btn-danger btn-sm" (click)="removeProventoAluguel(i)"><i class="fas fa-trash-alt"></i></button></td>
      </tr>
    </tbody>
</table>

</ion-content>