import { Component, OnInit, Renderer2 } from '@angular/core';
import { AlertController, ModalController, LoadingController, NavParams } from '@ionic/angular';
import { GlobalVars } from '../service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let $;

@Component({
  selector: 'app-aumenta-posicao',
  templateUrl: './aumenta-posicao.page.html',
  styleUrls: ['./aumenta-posicao.page.scss'],
})
export class AumentaPosicaoPage implements OnInit {
  private unlistnerIonLoadingDidDismiss: () => void;
  private unlistnerIonAlertWillPresent: () => void;
  private unlistnerIonAlertDidDismiss: () => void;
  private unlistnerIonModalWillDismiss: () => void;

  isRequesting = false;
  isModalAumentaPosicaoOpen = false;

  aumentaPosicaoIndex = -1;
  posicoesList = [];

  ionDateTime: any;

  dataResult: any;

  ativo = '';

  navParams = new NavParams();

  constructor(public globalVars: GlobalVars, private http: HttpClient, private loadingController: LoadingController, private modalCtrl: ModalController, private alertController: AlertController, private renderer: Renderer2) {
    this.aumentaPosicaoIndex = this.navParams.get('aumentaPosicaoIndex') ? this.navParams.get('aumentaPosicaoIndex') : -1;
  }

  ngOnInit() {
    if(this.aumentaPosicaoIndex > -1){
      this.posicoesList = this.globalVars.carteira[this.aumentaPosicaoIndex];
      this.ativo = this.globalVars.carteira[this.aumentaPosicaoIndex]['codigo'];
    }
  }

  closeAumentaPosicaoModal(){
    this.modalCtrl.dismiss();
  }

  exitAumentaPosicao(){
    this.modalCtrl.dismiss();
    this.alertController.dismiss();
  }

  async aumentarPosicao(index){
    this.aumentaPosicaoIndex = index;
    let myRenderer = this.renderer;

    const alertAumentarPosicao = this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Aumentar Posição',
      subHeader: this.globalVars.carteira[index]['codigo'],
      message: 'Preencha os campos abaixo.',
      inputs: [{
        id: 'quantidade_posicao',
        cssClass: 'error-check',
        type:'tel',
        placeholder: 'Quantidade',
      },{
        id: 'preco_posicao',
        cssClass: 'mask-money error-check',
        type:'tel',
        placeholder: 'Preço de compra',
      },{
        id: 'corretagem_posicao',
        cssClass: 'mask-money',
        type:'tel',
        placeholder: 'Corretagem + liquidação + impostos',
      },{
        id: 'data_posicao',
        cssClass: 'error-check',
        placeholder: 'Data de início',
      }],
      buttons: [{
          text: 'Histórico',
          handler: (data) => {
            this.openModalAumentaPosicao(index);
            return false;
          }
        },{
          text: 'Cancelar',
          role: 'cancel',
        },{
          text: 'ADICIONAR',
          handler: (data) => {
            let hasError = false;
            let elementList = document.getElementsByClassName("error-check");
            Array.prototype.forEach.call(elementList, function(el, index) {
                myRenderer.removeClass(el, 'input-error-class');
                
                if(el.value == '' || el.value == 'R$0,00'){
                  hasError = true;
                  myRenderer.addClass(el, 'input-error-class');
                }
            });

            if(hasError == false && !this.isRequesting){
              this.addAumentaPosicao();
            }
            
            return false;
          }
        }]
    });

    this.unlistnerIonAlertWillPresent = this.renderer.listen((await alertAumentarPosicao),'ionAlertWillPresent', () => {
      this.unlistnerIonAlertWillPresent();
      let el_quantidade_posicao = document.getElementById('quantidade_posicao');
      this.renderer.setAttribute(el_quantidade_posicao, 'name', 'quantidade_posicao');
      
      let el_preco_posicao = document.getElementById('preco_posicao');
      this.renderer.setAttribute(el_preco_posicao, 'name', 'preco_posicao');
      
      let el_corretagem_posicao = document.getElementById('corretagem_posicao');
      this.renderer.setAttribute(el_corretagem_posicao, 'name', 'corretagem_posicao');
      
      let el_data_posicao = document.getElementById('data_posicao');
      this.renderer.setAttribute(el_data_posicao, 'name', 'data_posicao');
      this.renderer.setAttribute(el_data_posicao, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_data_posicao, 'focus', () => { this.openIonDateTime(); });
      this.renderer.listen(el_data_posicao, 'click', () => { this.openIonDateTime(); });
      $('#data_posicao').after('<input type="hidden" id="empresa_aumenta_posicao" name="empresa_aumenta_posicao"> <input type="hidden" id="codigo_aumenta_posicao" name="codigo_aumenta_posicao"> <input type="hidden" id="ativo_id_aumenta_posicao" name="ativo_id_aumenta_posicao"> <ion-datetime id="ionDateTime" style="display:none" mode="ios" cancel-text="Cancelar" done-text="Selecionar" max="' + this.globalVars.today_date + '" picker-format="DDDD MMMM YYYY" display-format="DD MM YYYY" month-names="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"></ion-datetime>');
      
      let el_ionDateTime = document.getElementById('ionDateTime');
      this.renderer.listen(el_ionDateTime, 'ionChange', () => { this.ionDateTimeChange(event); });

      $(".mask-money").maskMoney({prefix:'R$', allowNegative: false, allowEmpty: true, allowZero: true, thousands:'.', decimal:',', affixesStay: true});
      
      $('#empresa_aumenta_posicao').val(this.globalVars.carteira[index]['empresa']);
      $('#codigo_aumenta_posicao').val(this.globalVars.carteira[index]['codigo']);
      $('#ativo_id_aumenta_posicao').val(this.globalVars.carteira[index]['ativo_id']);
      
      $('.alert-input-group').wrap('<form id="form_aumenta_posicao">');
    });

    this.unlistnerIonAlertDidDismiss = this.renderer.listen((await alertAumentarPosicao), 'ionAlertDidDismiss', () => {
      this.isRequesting = false;
      this.unlistnerIonAlertDidDismiss();
    });

    (await alertAumentarPosicao).present();
  }


  openIonDateTime(){
    this.ionDateTime = document.getElementById('ionDateTime');
    this.ionDateTime.open();
  }

  ionDateTimeChange(button){
    let newDate = button.target.value;

    let tempArray;

    if(newDate.indexOf('T') > -1){
      tempArray = newDate.split('T');
      newDate = tempArray[0];
    }    
  
    tempArray = newDate.split('-');
    newDate = tempArray[2] + '/' + tempArray[1] + '/' +  tempArray[0];
    
    $('#data_posicao').val(newDate);
  }



  async openModalAumentaPosicao(index){
    if(this.isModalAumentaPosicaoOpen == false){
      this.isModalAumentaPosicaoOpen = true;

      const modalAumentaPosicao = this.modalCtrl.create({
        component: AumentaPosicaoPage,
        componentProps: {aumentaPosicaoIndex: index}
      });

      this.unlistnerIonModalWillDismiss = this.renderer.listen((await modalAumentaPosicao), 'ionModalWillDismiss', () => {
        this.isModalAumentaPosicaoOpen = false;
        this.unlistnerIonModalWillDismiss();
      });

      (await modalAumentaPosicao).present();
    }
  }



  async addAumentaPosicao(){
    if(this.isRequesting || !this.globalVars.checkImportPendente()){
      return false;
    }

    this.isRequesting = true;

    const loading = this.loadingController.create({
      message: 'Adicionando nova posição... por favor aguarde.',
      duration: 15000
    });

    this.unlistnerIonLoadingDidDismiss = this.renderer.listen((await loading), 'ionLoadingDidDismiss', () => { 
      this.unlistnerIonLoadingDidDismiss();
      if(this.isRequesting){
        this.isRequesting = false;
        this.globalVars.showAlertInfo('Tempo esgotado', 'A tentativa de adicionar a nova posição falhou! Verifique se tem uma conexão com a internet e tente novamente.');
      }
    });

    (await loading).present();

    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    //preciso enviar no GET os dados de resgate filtro, pois se tiver cancelamento de resgate por conta do shortdaytrade, ele vai utilizar as info abaixo
    //&resgatesFiltro=' + this.globalVars.appConfig.resgatesFiltro + '&resgateOrdemFiltro=' + this.globalVars.appConfig.resgateOrdemFiltro + '&resgatesInicio=' + this.globalVars.appConfig.resgatesInicio + '&resgatesFim=' + this.globalVars.appConfig.resgatesFim

    this.http.post(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/proventos_aluguel_posicoes/addAumentaPosicao&resgatesFiltro=' + this.globalVars.appConfig.resgatesFiltro + '&resgateOrdemFiltro=' + this.globalVars.appConfig.resgateOrdemFiltro + '&resgatesInicio=' + this.globalVars.appConfig.resgatesInicio + '&resgatesFim=' + this.globalVars.appConfig.resgatesFim + '&session='+this.globalVars.appConfig.session, $('#form_aumenta_posicao').serialize(), {headers: headers}).subscribe(
      data =>{
        if(!this.isRequesting){
          return false;
        }

        this.isRequesting = false;
        this.loadingController.dismiss();
              
        this.dataResult = data;

        if(this.dataResult.error && this.dataResult.error == 'not_logged'){
          this.globalVars.showAlertInfo('Erro', 'not_logged aumenta posição');
          return false;
        }else if(this.dataResult.error){
          this.globalVars.showAlertInfo(this.dataResult.title, this.dataResult.error);
          return false;
        }

        this.globalVars.carteira = this.dataResult.carteira;
        this.globalVars.relatorio = this.dataResult.relatorio;
        this.globalVars.CEIdata = this.dataResult['ceiNextData'];
        this.globalVars.CEIlastUpdate = this.dataResult['lastUpdate'];
        this.globalVars.CEInextUpdate = this.dataResult['nextUpdate'];
        this.globalVars.needLoadExtrato = true;
        this.globalVars.needLoadImpostoRenda = true;
        this.globalVars.needLoadBalancoHistorico = true;
        this.globalVars.needLoadBalancoDiario = true;
        this.globalVars.needRequestHistoricoResgates = true;
        this.globalVars.isWeekend = false;
        this.posicoesList = this.globalVars.carteira[this.aumentaPosicaoIndex]; 
        this.alertController.dismiss();
        this.globalVars.loadCotacao(); 
      },
      error => {
        this.isRequesting = false;
        this.loadingController.dismiss();
      }
    );
  }


  removePosicao(index){
    if(!this.globalVars.checkImportPendente()){
      return false;
    }

    this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Apagar?',
      message: 'Deseja mesmo remover a posição?',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      },{
        text: 'Excluir',
        handler: () => {
         this.confirmRemovePosicao(index);
        }
      }]
    }).then(alertInfo=> alertInfo.present()); 
  }

  async confirmRemovePosicao(index){
    this.isRequesting = true;

    const loading = this.loadingController.create({
      message: 'Removendo posição... por favor aguarde.',
      duration: 15000
    });

    this.unlistnerIonLoadingDidDismiss = this.renderer.listen((await loading), 'ionLoadingDidDismiss', () => { 
      this.unlistnerIonLoadingDidDismiss();
      if(this.isRequesting){
        this.isRequesting = false;
        this.globalVars.showAlertInfo('Tempo esgotado', 'A tentativa de remover a posição falhou! Verifique se tem uma conexão com a internet e tente novamente.');
      }
    });

    (await loading).present();


    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let postFields = {
      posicao_id: this.posicoesList['posicoesList'][index]['id'],
      empresa_posicao: this.posicoesList['empresa'],
      codigo_ativo_posicao: this.posicoesList['codigo'],
      ativo_id_posicao: this.posicoesList['ativo_id'],
    }

    this.http.post(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/proventos_aluguel_posicoes/removePosicao&session='+this.globalVars.appConfig.session, postFields, {headers: headers}).subscribe(
      data =>{
        if(!this.isRequesting){
          return false;
        }

        this.isRequesting = false;
        this.loadingController.dismiss();
        
        this.dataResult = data;

        if(this.dataResult.error && this.dataResult.error == 'not_logged'){
          this.globalVars.showAlertInfo('Erro', 'not_logged remove posição');
          return false;
        }else if(this.dataResult.error){
          this.globalVars.showAlertInfo(this.dataResult.title, this.dataResult.error);
          return false;
        }

        this.globalVars.carteira = this.dataResult.carteira;
        this.globalVars.relatorio = this.dataResult.relatorio;
        this.globalVars.CEIdata = this.dataResult['ceiNextData'];
        this.globalVars.CEIlastUpdate = this.dataResult['lastUpdate'];
        this.globalVars.CEInextUpdate = this.dataResult['nextUpdate'];
        this.globalVars.needLoadExtrato = true;
        this.globalVars.needLoadImpostoRenda = true;
        this.globalVars.needLoadBalancoHistorico = true;
        this.globalVars.needLoadBalancoDiario = true;
        this.globalVars.isWeekend = false;
        this.posicoesList = this.globalVars.carteira[this.aumentaPosicaoIndex];
        this.globalVars.loadCotacao();
      },
      error => {
        this.isRequesting = false;
        this.loadingController.dismiss();
      }
    );
  }
}