import { Component, OnInit, Renderer2 } from '@angular/core';
import { ModalController, AlertController, ActionSheetController, IonDatetime, LoadingController, NavParams } from '@ionic/angular';
import { GlobalVars } from '../service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let $;

@Component({
  selector: 'app-proventos-aluguel',
  templateUrl: './proventos-aluguel.page.html',
  styleUrls: ['./proventos-aluguel.page.scss'],
})

export class ProventosAluguelPage implements OnInit {
  private unlistnerIonModalWillDismiss: () => void;
  private unlistnerIonAlertDidDismiss: () => void;
  private unlistnerIonAlertWillPresent: () => void;
  private unlistnerIonLoadingDidDismiss: () => void;

  isRequesting = false;
  isModalProventosAluguelOpen = false;
  isGettingExtrato = false;
  proventosAluguelList = [];
  
  ionDateTime: any;

  proventosAluguelIndex = -1;
  action = '';
  resgateID = 0;

  navParams = new NavParams();

  constructor(public globalVars: GlobalVars, private http: HttpClient, private renderer: Renderer2, private actionSheetController: ActionSheetController, private modalCtrl: ModalController, private alertController: AlertController, private loadingController: LoadingController) {
    this.proventosAluguelIndex = this.navParams.get('proventosAluguelIndex') ? this.navParams.get('proventosAluguelIndex') : -1;
  }

  ngOnInit() {
    if(this.proventosAluguelIndex > -1 && this.resgateID == 0){
      this.proventosAluguelList = this.globalVars.carteira[this.proventosAluguelIndex];
    }else if(this.proventosAluguelIndex > -1){
      this.proventosAluguelList = this.globalVars.historicoResgates['historico'][this.proventosAluguelIndex];
    }
  }

  closeProventosAluguelModal(){
    this.modalCtrl.dismiss();
  }

  exitProventosAluguel(){
    this.modalCtrl.dismiss();
    this.alertController.dismiss();
  }

  async proventosAluguel(index, action){
    this.proventosAluguelIndex = index;
    this.action = action;
    let myRenderer = this.renderer;
    let codigo = '';

    if(action == 'resgate'){
      codigo = this.globalVars.historicoResgates['historico'][index]['codigo'];
      this.resgateID = this.globalVars.historicoResgates['historico'][index]['id'];
          
    }else{
      codigo = this.globalVars.carteira[index]['codigo'];
      this.resgateID = 0;
    }
     
    const alertProventosAlugel = this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Proventos & Aluguel',
      subHeader: codigo,
      message: 'Preencha os campos abaixo.',
      inputs: [{
        id: 'descricao_provento_aluguel',
        cssClass: 'error-check',
        placeholder: 'Descrição',
      },{
        id: 'data_provento_aluguel',
        cssClass: 'error-check',
        placeholder: 'Data do recebimento',
      },{
        id: 'valor_provento_aluguel',
        cssClass: 'mask-money error-check',
        type:'tel',
        placeholder: 'Valor recebido',
      },{
        id: 'imposto_renda_aluguel',
        cssClass: 'mask-money error-check',
        type:'tel',
        placeholder: 'Valor do I.R.R.F',
      }],
      buttons: [{
          text: 'Histórico',
          handler: (data) => {
            this.openModalProventosAluguel(index);
            return false;
          }
        },{
          text: 'Cancelar',
          role: 'cancel',
        },{
          text: 'ADICIONAR',
          handler: (data) => {
            let hasError = false;
            let elementList = document.getElementsByClassName("error-check");
            Array.prototype.forEach.call(elementList, function(el, index) {
                myRenderer.removeClass(el, 'input-error-class');
                if( (el.value == '' && el.id != 'imposto_renda_aluguel') || el.value == 'R$0,00' || (el.id == 'imposto_renda_aluguel' && $('#descricao_provento_aluguel').val() == 'Aluguel' && el.value == '') ){
                  hasError = true;
                  myRenderer.addClass(el, 'input-error-class');
                }
            });

            if(hasError == false && !this.isRequesting){
              this.addProventosAluguel();
            }
            
            return false;
          }
        }]
    });

    this.unlistnerIonAlertWillPresent = this.renderer.listen((await alertProventosAlugel),'ionAlertWillPresent', () => {
      this.unlistnerIonAlertWillPresent();
      let el_descricao_provento_aluguel = document.getElementById('descricao_provento_aluguel');
      this.renderer.setAttribute(el_descricao_provento_aluguel, 'name', 'descricao_provento_aluguel');
      this.renderer.setAttribute(el_descricao_provento_aluguel, 'readonly', 'readonly');
      this.renderer.setAttribute(el_descricao_provento_aluguel, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_descricao_provento_aluguel, 'click', () => { this.descricaoProventosAluguel(); });

      let el_data_provento_aluguel = document.getElementById('data_provento_aluguel');
      this.renderer.setAttribute(el_data_provento_aluguel, 'name', 'data_provento_aluguel');
      this.renderer.setAttribute(el_data_provento_aluguel, 'onmousedown', 'event.preventDefault()');
      this.renderer.listen(el_data_provento_aluguel, 'focus', () => { this.openIonDateTime(); });
      this.renderer.listen(el_data_provento_aluguel, 'click', () => { this.openIonDateTime(); });
      $('#data_provento_aluguel').after('<input type="hidden" id="empresa_proventos_aluguel" name="empresa_proventos_aluguel"> <input type="hidden" id="codigo_proventos_aluguel" name="codigo_proventos_aluguel"> <input type="hidden" id="ativo_id_proventos_aluguel" name="ativo_id_proventos_aluguel"> <ion-datetime id="ionDateTime" style="display:none" mode="ios" cancel-text="Cancelar" done-text="Selecionar" picker-format="DDDD MMMM YYYY" display-format="DD MM YYYY" month-names="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"></ion-datetime>');
      
      let el_ionDateTime = document.getElementById('ionDateTime');
      this.renderer.listen(el_ionDateTime, 'ionChange', () => { this.ionDateTimeChange(event); });

      let el_valor_provento_aluguel = document.getElementById('valor_provento_aluguel');
      this.renderer.setAttribute(el_valor_provento_aluguel, 'name', 'valor_provento_aluguel');
      $(".mask-money").maskMoney({prefix:'R$', allowNegative: false, allowEmpty: true, allowZero: true, thousands:'.', decimal:',', affixesStay: true});
      
      let el_imposto_renda_aluguel = document.getElementById('imposto_renda_aluguel');
      this.renderer.setAttribute(el_imposto_renda_aluguel, 'name', 'imposto_renda_aluguel');
      $('#imposto_renda_aluguel').hide();

      if(action == 'resgate'){
        $('#empresa_proventos_aluguel').val(this.globalVars.historicoResgates['historico'][index]['empresa']);
        $('#codigo_proventos_aluguel').val(this.globalVars.historicoResgates['historico'][index]['codigo']);
        $('#ativo_id_proventos_aluguel').val(this.globalVars.historicoResgates['historico'][index]['ativo_id']);
      }else{
        $('#empresa_proventos_aluguel').val(this.globalVars.carteira[index]['empresa']);
        $('#codigo_proventos_aluguel').val(this.globalVars.carteira[index]['codigo']);
        $('#ativo_id_proventos_aluguel').val(this.globalVars.carteira[index]['ativo_id']);
      }   
      
      $('.alert-input-group').wrap('<form id="form_proventos_aluguel">');
    });

    this.unlistnerIonAlertDidDismiss = this.renderer.listen((await alertProventosAlugel), 'ionAlertDidDismiss', () => {
      this.isRequesting = false;
      this.unlistnerIonAlertDidDismiss();
    });

    (await alertProventosAlugel).present();  
  }



  async descricaoProventosAluguel(){
    const actionSheetProventosAluguel = this.actionSheetController.create({
      header: 'Descrição',
      mode: 'md',
      buttons: [{
        text: 'Aluguel',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Aluguel'); $('#imposto_renda_aluguel').val(''); $('#imposto_renda_aluguel').show(); }, 500);
          return false;
        }
      },{
        text: 'Bonificação',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Bonificação'); $('#imposto_renda_aluguel').val('---'); $('#imposto_renda_aluguel').hide(); }, 500);
          return false;
        }
      },{
        text: 'Direitos de subscrição',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Direitos de subscrição'); $('#imposto_renda_aluguel').val('---'); $('#imposto_renda_aluguel').hide(); }, 500);
          return false;
        }
      },{
        text: 'Dividendos',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Dividendos'); $('#imposto_renda_aluguel').val('---'); $('#imposto_renda_aluguel').hide(); }, 500);
          return false;
        }
      },{
        text: 'Juros sobre capital próprio',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Juros sobre capital próprio'); $('#imposto_renda_aluguel').val('---'); $('#imposto_renda_aluguel').hide(); }, 500);
          return false;
        }
      },{
        text: 'Rendimentos',
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => { $('#descricao_provento_aluguel').val('Rendimentos'); $('#imposto_renda_aluguel').val('---'); $('#imposto_renda_aluguel').hide(); }, 500);
          return false;
        }
      }],
    });

    (await actionSheetProventosAluguel).present();
  }



  async openModalProventosAluguel(index){
    if(!this.isModalProventosAluguelOpen){
      this.isModalProventosAluguelOpen = true;

      this.proventosAluguelIndex = index;

      const modalProventosAlugel = this.modalCtrl.create({
        component: ProventosAluguelPage,
        componentProps: {proventosAluguelIndex: index}
      });

      this.unlistnerIonModalWillDismiss = this.renderer.listen((await modalProventosAlugel), 'ionModalWillDismiss', () => {
        this.isModalProventosAluguelOpen = false;
        this.unlistnerIonModalWillDismiss();
      });

      (await modalProventosAlugel).present();
    }
  }


  openIonDateTime(){
    this.ionDateTime = document.getElementById('ionDateTime');
    this.ionDateTime.open();
  }

  ionDateTimeChange(button){
    let newDate = button.target.value;

    let tempArray;

    if(newDate.indexOf('T') > -1){
      tempArray = newDate.split('T');
      newDate = tempArray[0];
    }    
  
    tempArray = newDate.split('-');
    newDate = tempArray[2] + '/' + tempArray[1] + '/' +  tempArray[0];
    
    $('#data_provento_aluguel').val(newDate);
  }


  async addProventosAluguel(){
    if(this.isRequesting){
      return false;
    }

    this.isRequesting = true;

    let descricao = ($('#descricao_provento_aluguel').val() == 'Aluguel' ? 'aluguel' : 'provento' );

    const loading = this.loadingController.create({
      message: 'Adicionando ' + descricao + '... por favor aguarde.',
      duration: 15000
    });

    this.unlistnerIonLoadingDidDismiss = this.renderer.listen((await loading), 'ionLoadingDidDismiss', () => { 
      this.unlistnerIonLoadingDidDismiss();
      if(this.isRequesting){
        this.isRequesting = false;
        this.globalVars.showAlertInfo('Tempo esgotado', 'A tentativa de adicionar o ' + descricao + ' falhou! Verifique se tem uma conexão com a internet e tente novamente.');
      }
    });

    (await loading).present();

    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let route = (this.resgateID > 0 ? 'resgate/addProventosAluguel&resgate_id=' + this.resgateID + '&resgatesFiltro=' + this.globalVars.appConfig.resgatesFiltro + '&resgateOrdemFiltro=' + this.globalVars.appConfig.resgateOrdemFiltro + '&resgatesInicio=' + this.globalVars.appConfig.resgatesInicio + '&resgatesFim=' + this.globalVars.appConfig.resgatesFim : 'proventos_aluguel_posicoes/addProventosAluguel');

    this.http.post(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/' + route + '&session='+this.globalVars.appConfig.session, $('#form_proventos_aluguel').serialize(), {headers: headers}).subscribe(
      data =>{
        if(!this.isRequesting){
          return false;
        }
        this.isRequesting = false;
        this.loadingController.dismiss();
        this.alertController.dismiss();

        const dataResult:any = data;

        if(dataResult.error && dataResult.error == 'not_logged'){
          alert('ADD PROVENTO-ALUGUEL NOT LOGGED');
        }else if(dataResult.error){
          this.globalVars.showAlertInfo(dataResult.title, dataResult.error);
          return false;
        }

        this.updateDados(dataResult);        
      },
      error => {
        this.isRequesting = false;
        this.loadingController.dismiss();
      }
    );
  }



  removeProventoAluguel(index){
    let descricao = (this.proventosAluguelList['proventosAluguelList'][index]['tipo'] == 'Aluguel' ? 'aluguel' : 'provento <b>' + this.proventosAluguelList['proventosAluguelList'][index]['tipo'] + '</b>')

    this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      header: 'Apagar?',
      message: 'Deseja mesmo remover o ' + descricao + ' da sua carteira <b>' + this.proventosAluguelList['codigo'] + '</b>?',
      buttons: [{
        text: 'Cancelar',
        role: 'cancel'
      },{
        text: 'Excluir',
        handler: () => {
         this.confirmRemoveProventoAluguel(index);
        }
      }]
    }).then(alertInfo=> alertInfo.present()); 
  }

  async confirmRemoveProventoAluguel(index){
    this.isRequesting = true;

    let descricao = (this.proventosAluguelList['proventosAluguelList'][index]['tipo'] == 'Aluguel' ? 'aluguel' : 'provento')

    const loading = this.loadingController.create({
      message: 'Removendo ' + descricao + '... por favor aguarde.',
      duration: 15000
    });

    this.unlistnerIonLoadingDidDismiss = this.renderer.listen((await loading), 'ionLoadingDidDismiss', () => { 
      this.unlistnerIonLoadingDidDismiss();
      if(this.isRequesting){
        this.isRequesting = false;
        this.globalVars.showAlertInfo('Tempo esgotado', 'A tentativa de remover o ' + descricao + ' falhou! Verifique se tem uma conexão com a internet e tente novamente.');
      }
    });

    (await loading).present();

    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let postFields = {
      provento_aluguel_id: this.proventosAluguelList['proventosAluguelList'][index]['id'],
      provento_aluguel_tipo: this.proventosAluguelList['proventosAluguelList'][index]['tipo'],
      empresa_proventos_aluguel: this.proventosAluguelList['empresa'],
      codigo_proventos_aluguel: this.proventosAluguelList['codigo'],
      ativo_id_proventos_aluguel: this.proventosAluguelList['ativo_id'],
    }

    let route = (this.resgateID > 0 ? 'resgate/removeProventosAluguel&resgate_id=' + this.resgateID + '&resgatesFiltro=' + this.globalVars.appConfig.resgatesFiltro + '&resgateOrdemFiltro=' + this.globalVars.appConfig.resgateOrdemFiltro + '&resgatesInicio=' + this.globalVars.appConfig.resgatesInicio + '&resgatesFim=' + this.globalVars.appConfig.resgatesFim : 'proventos_aluguel_posicoes/removeProventosAluguel');

    this.http.post(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/' + route + '&session='+this.globalVars.appConfig.session, postFields, {headers: headers}).subscribe(
      data =>{
        if(!this.isRequesting){
          return false;
        }

        this.isRequesting = false;
        this.loadingController.dismiss();
        
        const dataResult:any = data;

        if(dataResult.error && dataResult.error == 'not_logged'){
          alert('REMOVE PROVENTO-ALUGUEL NOT LOGGED');
        }else if(dataResult.error){
          this.globalVars.showAlertInfo(dataResult.title, dataResult.error);
          return false;
        }
        
        this.updateDados(dataResult);
      },
      error => {
        this.isRequesting = false;
        this.loadingController.dismiss();
      }
    );
  }

  updateDados(dataResult){
    requestAnimationFrame(() => {
      this.globalVars.isWeekend = false;
      this.globalVars.needLoadExtrato = true;
      this.globalVars.needLoadImpostoRenda = true;
      this.globalVars.needLoadBalancoHistorico = true;
      this.globalVars.needLoadBalancoDiario = true;
      
      this.globalVars.relatorio = dataResult.relatorio;
      this.globalVars.relatorio["saldo_para_investimento"] = dataResult['saldo'];

      if(this.resgateID > 0){
        this.globalVars.historicoResgates = dataResult['historicoResgates'];
        this.proventosAluguelList = this.globalVars.historicoResgates['historico'][this.proventosAluguelIndex];
      }else{
        this.globalVars.carteira = dataResult.carteira;
        this.proventosAluguelList = this.globalVars.carteira[this.proventosAluguelIndex];
      }

      this.globalVars.loadCotacao();
    });
  }
}
