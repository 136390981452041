<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closeAumentaPosicaoModal()">Voltar</ion-button>
    </ion-buttons>
    <ion-title class="c-ion-title">Posições {{ativo}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exitAumentaPosicao()">Fechar</ion-button>
    </ion-buttons> 
   </ion-toolbar>
 </ion-header>

 <ion-content #contentAumentaPosicao [fullscreen]="true">

  <table class="table table-striped table-hover" [ngClass]="{'table-dark':globalVars.appConfig.darkMode}">
    <thead>
        <tr class="text-center table-bordered">
          <td class="text-center table-bordered" valign="middle"><b>Qtd</b></td>
          <td width="20%" class="text-center table-bordered" valign="middle"><b>Data</b></td>
          <td width="20%" class="text-center table-bordered" valign="middle"><b>Valor</b></td>
          <td width="20%" class="text-center table-bordered" valign="middle"><b>Taxas</b></td>
          <td width="16%" class="text-center table-bordered" valign="middle"><b>Ação</b></td>
        </tr>
    </thead>
    
    <tbody>
      <tr class="table-bordered" *ngIf="posicoesList['posicoesList'].length == 0">
        <td colspan="5" class="text-center table-bordered">Nenhum aumento de posição adicionado ainda!</td>
      </tr>

      <tr *ngFor="let posicao of posicoesList['posicoesList']; let i = index">
        <td class="text-center table-bordered" style="vertical-align:middle">{{posicao["qtd"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle">{{posicao["data"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle">{{posicao["preco"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle">{{posicao["taxas"]}}</td>
        <td class="text-center table-bordered" style="vertical-align:middle"><button type="button" class="btn btn-danger btn-sm" (click)="removePosicao(i)"><i class="fas fa-trash-alt"></i></button></td>
      </tr>
    </tbody>
</table>

</ion-content>