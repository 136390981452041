<ion-header>
  <ion-toolbar>
     <ion-title class="c-ion-title">Mercado de Opções</ion-title>
    <ion-buttons slot="start">
      <ion-button *ngIf="showBackButton" (click)="backMainPage()">Voltar</ion-button>
      <ion-button *ngIf="!showBackButton" (click)="openBusca('Ativo')">Buscar</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="closeOpcoesModal()">Fechar</ion-button>
    </ion-buttons>
   </ion-toolbar>
 </ion-header>

 <ion-content force-overscroll="false" (window:resize)="onResize()">
  <ion-slides #opcoesSlide [options]="slideOpts" (ionSlideDidChange)="ionSlideDidChange()">
    <ion-slide style="min-width: 100% !important; max-width: 100% !important; padding: 10px;">
      <div class="wrapper-dados">
        <table width="100%">
          <tr>
            <td>
              <ion-list-header mode="md"><ion-text color="medium"><i class="fas fa-chevron-down chevron"></i> Principais</ion-text></ion-list-header>
            </td>
          </tr>
          <tr>
            <td>
              <div class="grid-tickers-container">
                <div class="grid-tickers-item" *ngFor="let principais of principaisLista; let i = index;">
                  <ion-card button (click)="prepareGetOpcoesList(i, 'principal')">
                    <div class="principais-tickers">
                      <img class="{{(principais['codigo'] | slice:0:4)}}" src="http://instabov.images.xtremebh.com.br/ticker-empty.png" />
                    </div>
                    {{principais['codigo']}}
                  </ion-card>
                </div>
              </div>
            </td>
          </tr>
          <tr height="15"></tr>
          <tr>
            <td>
              <ion-list-header mode="md"><ion-text color="medium"><i class="fas fa-chevron-down chevron"></i> Acesso recente</ion-text></ion-list-header>
            </td>
          </tr>
          <tr>
            <td>
              <div *ngIf="!isRecentesLoaded" style="margin-top: 20px;"><ion-spinner></ion-spinner></div>
              <div class="grid-tickers-container" *ngIf="isRecentesLoaded">
                <div class="grid-tickers-item" *ngFor="let recentes of recentesLista; let i = index;">
                  <ion-card button (click)="prepareGetOpcoesList(i, 'recentes')">
                    <div class="principais-tickers">
                      <img class="{{(recentes['codigo'] | slice:0:4)}}" src="http://instabov.images.xtremebh.com.br/ticker-empty.png" />
                    </div>
                    {{recentes['codigo']}}
                  </ion-card>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </ion-slide>
    
    <ion-slide style="min-width: 100% !important; max-width: 100% !important">
      <div class="wrapper-dados">
        <table width="100%">
          <tr>
            <td width="15"></td>
            <td width="15%"><img class="{{(codigoAtivo | slice:0:4)}}" src="http://instabov.images.xtremebh.com.br/ticker-empty.png" /></td>
            <td align="left" class="empresa-nome">
              {{codigoAtivo}}<br />
              <small>{{nomeAtivo}}</small><br />
              <span class="cotacao-ativo" *ngIf="!cotacaoPrincipalLoaded">R$0,00 | <small>+R$0,00 (0.00%)</small></span>
              <span class="cotacao-ativo" *ngIf="cotacaoPrincipalLoaded" class="{{opcoesList['cotacao'][codigoAtivo]['styleVariacao']}}">{{opcoesList['cotacao'][codigoAtivo]['cotacao']}} | <small>{{opcoesList['cotacao'][codigoAtivo]['variacao_valor']}} ({{opcoesList['cotacao'][codigoAtivo]['variacao']}}%)</small></span>
            </td>
            <td align="right">
              <ion-button fill="clear" (click)="openGrafico(codigoAtivo, 'Normal')"><ion-icon class="icon-grafico" name="bar-chart"></ion-icon></ion-button>
            </td>
            <td width="15"></td>
          </tr>
        </table>

        <div *ngIf="!opcoesDadosLoaded" class="loading-flex-container" style="font-size: 15px">
          <div>
            <ion-spinner></ion-spinner><br />
            Acessando lista... por favor aguarde.
          </div>
        </div>
        
        <div class="wrapper-table-config" *ngIf="opcoesDadosLoaded">
          <table width="100%" class="table-config">
            <tr>
              <td>
                <small>Vencimento</small><br />
                <ion-select #vencimentoSelect [interfaceOptions]="vencimentoOptions" interface="alert" value="{{opcoesList['vencimentoSelected']}}" mode="ios" (ionChange)="getSelectFilter('vencimento')">
                  <ng-container *ngFor="let vencimento of opcoesList['vencimentosList']; let i = index;">
                    <ion-select-option value="{{vencimento}}">{{vencimento | date:'dd/MM/yyyy'}}</ion-select-option>
                  </ng-container>
                </ion-select>
              </td>
              <td>
                <small>Tipo</small><br />
                <ion-select #tipoSelect [interfaceOptions]="tipoOptions" value="{{tipoSelected}}" mode="ios" (ionChange)="getSelectFilter('tipo')">
                  <ion-select-option value="all">Todas</ion-select-option>
                  <ion-select-option value="Call">Call</ion-select-option>
                  <ion-select-option value="Put">Put</ion-select-option>
                </ion-select>
              </td>
              <td>
                <small>Estilo</small><br />
                <ion-select #estiloSelect [interfaceOptions]="estiloOptions" value="{{estiloSelected}}" mode="ios" (ionChange)="getSelectFilter('estilo')">
                  <ion-select-option value="all">Todas</ion-select-option>
                  <ion-select-option value="A">Americana</ion-select-option>
                  <ion-select-option value="E">Europeia</ion-select-option>
                </ion-select>
              </td>
              <td width="15"></td>
            </tr>
            <tr>
              <td>
                <small>Início Strike</small><br />
                <ion-select #inicioStrikeSelect [interfaceOptions]="inicioOptions" value="{{opcoesList['inicioStrikeSelected']}}" mode="ios" (ionChange)="getSelectFilter('inicioStrike')">
                  <ng-container *ngFor="let inicioStrike of opcoesList['inicioStrikesList']; let i = index;">
                    <ion-select-option value="{{inicioStrike}}">{{inicioStrike | currency:'BRL':'symbol':'.2-2':'pt'}}</ion-select-option>
                  </ng-container>
                </ion-select>
              </td>
              <td>
                <small>Fim Strike</small><br />
                <ion-select #fimStrikeSelect [interfaceOptions]="fimOptions" value="{{opcoesList['fimStrikeSelected']}}" mode="ios" (ionChange)="getSelectFilter('fimStrike')">
                  <ng-container *ngFor="let fimStrike of opcoesList['fimStrikesList']; let i = index;">
                    <ion-select-option value="{{fimStrike}}">{{fimStrike | currency:'BRL':'symbol':'.2-2':'pt'}}</ion-select-option>
                  </ng-container>
                </ion-select>
              </td>
              <td><ion-button size="small" shape="round" (click)="updateWithFilter()" disabled="{{updateButtonDisabled ? 'true' : 'false'}}"><i class="fas fa-sync"></i></ion-button></td>
              <td width="15"></td>
            </tr>
          </table>
              
        </div>

        <div class="dias-vencimento" *ngIf="opcoesDadosLoaded"><small>{{opcoesList['diasAteVencimento']}}</small></div>

        <table width="100%" *ngIf="opcoesDadosLoaded">
          <tr>
            <td [style.width.px]="td_head">
              <table class="table table-striped" [ngClass]="{'table-dark':globalVars.appConfig.darkMode}">
                <tbody>
                  <tr *ngFor="let opcoes of opcoesList['opcoesList']; let i = index;">
                    <td class="td-head">{{opcoes['thead']}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td [style.width.px]="td_opcoes">
              <div class="wrapper-opcoes" [style.width.px]="td_opcoes">
                <table class="table table-striped" [ngClass]="{'table-dark':globalVars.appConfig.darkMode}">
                  <tbody>
                    <tr *ngFor="let opcoes of opcoesList['opcoesList']; let i = index;">
                      <ng-container *ngFor="let opcaoDados of opcoes['tbody']; let g = index;">
                        <td align="center">
                          <div class="td-opcoes">
                            <span *ngIf="opcoes['thead'] == 'Strike'">{{opcaoDados | currency:'BRL':'symbol':'.2-2':'pt'}}</span>
                            <span *ngIf="opcoes['thead'] == 'Cotação'"><span (click)="showCotacaoInfo(i, g)" class="{{opcoesList['cotacao'][opcoesList['opcoesList'][0]['tbody'][g]]['styleVariacao']}}">[ {{opcoesList['cotacao'][opcoesList['opcoesList'][0]['tbody'][g]]['cotacao']}} ]</span> <i (click)="openGrafico(opcoesList['opcoesList'][0]['tbody'][g], 'Opção')" class="far fa-chart-bar icon-grafico-opcao"></i></span>
                            <span *ngIf="opcoes['thead'] == 'Volume'">{{opcoesList['cotacao'][opcoesList['opcoesList'][0]['tbody'][g]]['volume']}}</span>
                            <span *ngIf="opcoes['tkind'] == 'opcaoDados'">{{opcaoDados}}</span>
                            <span *ngIf="opcoes['thead'] == 'Intrínsico'">{{opcoesList['cotacao'][opcoesList['opcoesList'][0]['tbody'][g]]['intrinsico'] | currency:'BRL':'symbol':'.2-2':'pt'}}</span>
                            <span *ngIf="opcoes['thead'] == 'Extrínsico'">{{opcoesList['cotacao'][opcoesList['opcoesList'][0]['tbody'][g]]['extrinsico'] | currency:'BRL':'symbol':'.2-2':'pt'}}</span>
                          </div>
                        </td>
                      </ng-container>
                      <td><div [style.width.px]="td_opcoes - td_head"></div></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <ion-range class="range-preload" mode="md"></ion-range>
    </ion-slide>
  </ion-slides>
 </ion-content>