<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="resgateHistoricoHasLoaded">
      <ion-button (click)="openFiltro()">Filtro <small style="padding-left: 5px;">{{currentActiveFilter}}</small></ion-button>
    </ion-buttons>
    <ion-title class="c-ion-title">Histórico Vendas</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeResgates()">Fechar</ion-button>
    </ion-buttons>
   </ion-toolbar>
</ion-header>

<ion-content #contentResgates [fullscreen]="true" [ngClass]="{'grid-dados-container-light':!globalVars.appConfig.darkMode}">
  <ion-datetime #ionDateTimeFilter hidden="true" (ionChange)="ionDateTimeFilterChange($event)" mode="ios" cancel-text="Cancelar" done-text="Selecionar" picker-format="DDDD MMMM YYYY" display-format="DD MM YYYY" month-names="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"></ion-datetime>

  <div class="valign-middle">
    <div *ngIf="!resgateHistoricoHasLoaded">
      <ion-text color="medium">
        <ion-spinner></ion-spinner><br />
        <ion-text>Acessando... por favor aguarde.</ion-text>
      </ion-text>
    </div>

    <div *ngIf="resgateHistoricoHasLoaded && globalVars.historicoResgates['historico'].length == 0">
      <ion-text color="medium">
        <ion-icon name="warning-outline" class="icon-empty"></ion-icon><br />
        Nenhum resgate no período selecionado!
      </ion-text>
    </div>
  </div>

  <div *ngIf="resgateHistoricoHasLoaded">
    <ion-card *ngFor="let ativo of globalVars.historicoResgates['historico']; let i = index">
      <ion-progress-bar [color]="ativo.lucro_prejuizo_venda_status.split('negativo').join('danger').split('positivo').join('success').split('neutro').join('medium')" value="1"></ion-progress-bar>

      <ion-card-header>
        <!--LOGO | TICKER-->
        <div class="grid-logo-container">
          <div class="grid-logo-item">
            <ion-skeleton-text *ngIf="!ativo.loaded" animated></ion-skeleton-text>
            <img class="{{(ativo['codigo'] | slice:0:4)}}" (load)="logoAtivoLoaded(i)" src="http://instabov.images.xtremebh.com.br/ticker-empty.png" [ngClass]="{'hide-logo':!ativo.showLogo}" />
          </div>
          <div class="grid-logo-item grid-ticker">
            <b>{{ativo.codigo}}</b><br />
            <small>{{ativo.nome}}</small>
          </div>
          <div class="grid-logo-item grid-tipo-operacao">
            <ion-button fill="clear" size="small" [color]="ativo.lucro_prejuizo_venda_status.split('negativo').join('danger').split('positivo').join('success').split('neutro').join('medium')">
              <div class="tipo-operacao-icon" [innerHtml]="ativo.tipo_operacao_icon"></div>
            </ion-button>
          </div>
          <div class="grid-logo-item grid-dados-right">
            <ion-button class="button-ativo-options" fill="clear" (click)="getResgateOptions(i)" color="medium"><i class="fa fa-ellipsis-v"></i></ion-button>
        </div>
        </div>
      </ion-card-header>

      <ion-card-content>
        <!--PRECO EXERCICIO | VENCIMENTO | TIPO OPCAO-->
      <div class="grid-dados-container" *ngIf="ativo.tipo_operacao.includes('Opção')">
        <div class="grid-dados-left">
          <span class="small-titulos">Preço de Exercício</span><br />{{ativo.strike_opcao}}
        </div>
        <div class="grid-dados-center">
          <span class="small-titulos">Vencimento</span><br />{{ativo.vencimento_opcao}}
        </div>
        <div class="grid-dados-right">
          <span class="small-titulos">Tipo de Negócio</span><br />{{ativo.tipo_opcao}} {{ativo.estilo_opcao}}
        </div>
      </div>
   
      <!--PRECO COMPRA | QUANTIDADE COMPRA | DATA COMPRA-->
      <div class="grid-dados-container">
        <div class="grid-dados-left">
          <span class="small-titulos">{{ativo.label_preco_compra}}</span><br />{{ativo.preco_compra}}
        </div>
        <div class="grid-dados-center">
          <span class="small-titulos">{{ativo.label_qtd_compra}}</span><br />{{ativo.quantidade_compra}}
        </div>
        <div class="grid-dados-right">
          <span class="small-titulos">{{ativo.label_data_compra}}</span><br />{{ativo.data_compra}}
        </div>
      </div>

      <!--CORRETAGEM COMPRA | TOTAL COMPRA-->
      <div class="grid-dados-container span-2">
        <div class="grid-dados-left">
          <span class="small-titulos">Corretagem</span><br />{{ativo.corretagem_compra}} <i (click)="showInfo('corretagem_compra_zero')" class="fas fa-info-circle" *ngIf="ativo.corretagem_compra == 'R$0,00' && ativo.quantidade_compra != ativo.quantidade_venda"></i>
        </div>
        <div class="grid-dados-right">
          <span class="small-titulos">Valor Investido</span><br />{{ativo.total_investido}}
        </div>
      </div>

      <!--PRECO VENDA | QUANTIDADE VENDA | DATA VENDA-->
      <div class="grid-dados-container">
        <div class="grid-dados-left">
          <span class="small-titulos">{{ativo.label_preco_venda}}</span><br />{{ativo.preco_venda}}
        </div>
        <div class="grid-dados-center">
          <span class="small-titulos">{{ativo.label_qtd_venda}}</span><br />{{ativo.quantidade_venda}}
        </div>
        <div class="grid-dados-right">
          <span class="small-titulos">{{ativo.label_data_venda}}</span><br />{{ativo.data_venda}}
        </div>
      </div>

      <!--CORRETAGEM VENDA | TOTAL VENDA-->
      <div class="grid-dados-container span-2">
        <div class="grid-dados-left">
          <span class="small-titulos">Corretagem</span><br />{{ativo.corretagem_venda}}
        </div>
        <div class="grid-dados-right">
          <span class="small-titulos">{{ativo.label_total_venda}}</span><br />{{ativo.total_valor_venda}}
        </div>
      </div>

      <!--PROVENTOS | ALUGUEL-->
      <div class="grid-dados-container span-2" [ngClass]="{'span-3': (ativo.proventos_lucro != 'R$0,00' && ativo.aluguel_lucro == 'R$0,00') || (ativo.proventos_lucro == 'R$0,00' && ativo.aluguel_lucro != 'R$0,00')}" *ngIf="ativo.proventos_lucro != 'R$0,00' || ativo.aluguel_lucro != 'R$0,00'">
        <div *ngIf="ativo.proventos_lucro != 'R$0,00'" [ngClass]="{'grid-dados-left': ativo.proventos_lucro != 'R$0,00' && ativo.aluguel_lucro == 'R$0,00', 'grid-dados-center': ativo.aluguel_lucro == 'R$0,00'}">
          <span (click)="showInfo('lucro_proventos')">
            <span class="small-titulos">Lucro c/ Proventos</span><br />
            {{ativo.proventos_lucro}} <i class="fas fa-info-circle"></i>
          </span>
        </div>
        <div *ngIf="ativo.aluguel_lucro != 'R$0,00'" [ngClass]="{'grid-dados-right': ativo.proventos_lucro != 'R$0,00' && ativo.aluguel_lucro != 'R$0,00', 'grid-dados-center': ativo.proventos_lucro == 'R$0,00'}">
          <span (click)="showInfo('lucro_aluguel')">
            <span class="small-titulos">Lucro c/ Aluguel</span><br />
            {{ativo.aluguel_lucro}} <i class="fas fa-info-circle"></i>
          </span>
        </div>
      </div>

      <!--IMPOSTO DAYTRADE | IMPOSTO RETIDO | IMPOSTO SWINGTRADE-->
      <div class="grid-dados-container">
        <div class="grid-dados-left" (click)="showInfo('imposto_day_trade')">
          <span class="small-titulos">Imp. Day Trade</span><br />{{ativo.imposto_daytrade_venda}} <i class="fas fa-info-circle"></i>
        </div>
        <div class="grid-dados-center" (click)="showInfo('imposto_retido')">
          <span class="small-titulos">I.R.R.F</span><br />{{ativo.imposto_retido_fonte}} <i class="fas fa-info-circle"></i>
        </div>
        <div class="grid-dados-right" (click)="showInfo('imposto_swing_trade')">
          <span class="small-titulos">Imp. Swing Trade</span><br />{{ativo.imposto_swingtrade_venda}} <i class="fas fa-info-circle"></i>
        </div>
      </div>

      <!--TEMPO-->
      <div class="grid-dados-container span-3">
        <div class="grid-dados-center">
          <span class="small-titulos">Tempo</span><br /><span class="ativo-tempo">{{ativo.tempo}}</span>
        </div>
      </div>
     
      <!--LUCRO PREJUIZO FINAL & PATRIMONIO NA DATA-->
      <div class="grid-dados-container span-3">
        <div class="grid-dados-center">
          <span class="small-titulos">Lucro/Prejuízo Final</span><br />
          <div [ngClass]="{'negativo-text':ativo.lucro_prejuizo_venda_status == 'negativo', 'positivo-text':ativo.lucro_prejuizo_venda_status == 'positivo'}">
            {{ativo.lucro_prejuizo_venda}}
            <span *ngIf="ativo.lucro_prejuizo_venda_status == 'negativo'"><small>({{ativo.porcentagem_lucro_venda}}%) <i class="fa fa-arrow-down size-arrows"></i></small></span>
            <span *ngIf="ativo.lucro_prejuizo_venda_status == 'positivo'"><small>(+{{ativo.porcentagem_lucro_venda}}%) <i class="fa fa-arrow-up size-arrows"></i></small></span>
            <span *ngIf="ativo.lucro_prejuizo_venda_status == 'neutro'"><small>({{ativo.porcentagem_lucro_venda}}%) <i class="fa fa-arrow-right size-arrows"></i></small></span>
          </div>
          <br />
          <div (click)="showInfo('patrimonio_na_data')">
            <span class="small-titulos">Patrimônio na Data </span> <i class="fas fa-info-circle"></i><br />
            <div [ngClass]="{'negativo-text':ativo.porcentagem_lucro_patrimonio_status == 'negativo', 'positivo-text':ativo.porcentagem_lucro_patrimonio_status == 'positivo'}">
              {{ativo.patrimonio_data_venda}}
              <span *ngIf="ativo.porcentagem_lucro_patrimonio_status == 'negativo'"><small>({{ativo.porcentagem_lucro_patrimonio}}%) <i class="fa fa-arrow-down size-arrows"></i></small></span>
              <span *ngIf="ativo.porcentagem_lucro_patrimonio_status == 'positivo'"><small>(+{{ativo.porcentagem_lucro_patrimonio}}%) <i class="fa fa-arrow-up size-arrows"></i></small></span>
              <span *ngIf="ativo.porcentagem_lucro_patrimonio_status == 'neutro'"><small>({{ativo.porcentagem_lucro_patrimonio}}%) <i class="fa fa-arrow-right size-arrows"></i></small></span>
            </div>
          </div>
        </div>
      </div>
      
      <!--EXPAND | CORRETORA | EXTRAS-->
      <div class="grid-dados-container grid-dados-corretora">
        <div class="grid-dados-left">
          <ion-button (click)="showAtivoObservacao(i)" fill="clear" size="small" class="button-info" *ngIf="ativo.observacoes != ''"><i class="fas fa-info-circle fas-obs"></i></ion-button>
        </div>
        <div class="grid-dados-center">
          <small>{{ativo.corretora}}</small>
        </div>
        <div class="grid-dados-right">
            <ion-button (click)="ativoExtraActions.showExtraActions(i, $event)" fill="clear" size="small" class="button-bars"><i class="fas fa-bars"></i></ion-button>
        </div>
      </div>

      
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>