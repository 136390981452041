import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { GlobalVars } from '../service';
import { BuscaPage } from '../busca/busca.page';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { GraficoPage } from '../grafico/grafico.page';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/pt'
registerLocaleData(localeIt, 'pt');

declare var $;

@Component({
  selector: 'app-opcoes',
  templateUrl: './opcoes.page.html',
  styleUrls: ['./opcoes.page.scss'],
})
export class OpcoesPage implements OnInit {
  @ViewChild('vencimentoSelect') vencimentoSelect;
  @ViewChild('tipoSelect') tipoSelect;
  @ViewChild('estiloSelect') estiloSelect;
  @ViewChild('inicioStrikeSelect') inicioStrikeSelect;
  @ViewChild('fimStrikeSelect') fimStrikeSelect;
  @ViewChild('opcoesSlide') slider: IonSlides;
  
  listParams = {ticker: '', nome: ''};
  currentSlide = 0;
  principaisLista = [];
  recentesLista = [];
  isRecentesLoaded = false;

  showBackButton = false;
  isModalBuscaOpen = false;
  isModalGraficoOpen = false;

  vencimento = '';
  tipoSelected = 'all';
  estiloSelected = 'all';

  selectedIndex = 0;
  opcaoCategoria = '';
  opcoesList:any = [];
  opcoesListOriginal:any = [];
  codigoAtivo = '';
  nomeAtivo = '';

  inicioStrikeSelected = '';
  fimStrikeSelected = '';

  td_head = 30;
  td_opcoes = window.innerWidth - this.td_head - 45; //ultimo e padding

  opcoesDadosLoaded = false;
  updateButtonDisabled = true;
  cotacaoPrincipalLoaded = false;
  
  vencimentoOptions = {
    header: 'Vencimento',     
  };
  tipoOptions = {
    header: 'Tipo',     
  };
  estiloOptions = {
    header: 'Estilo',     
  };
  inicioOptions = {
    header: 'Início Strike',     
  };
  fimOptions = {
    header: 'Fim Strike',     
  };


  slideOpts = {
    allowTouchMove: false
  };

  timeoutAtualizaCotacoes;

  constructor(public globalVars: GlobalVars, private storage: Storage, private http: HttpClient, private renderer: Renderer2, private modalCtrl: ModalController, private navParams: NavParams) {
    this.listParams = {
      ticker: navParams.get('ticker'),
      nome: navParams.get('nome')
    } 
  }

  ngOnInit() {
    for(let i=0; i < this.globalVars.listaAtivos.length; i++){
      if(this.globalVars.listaAtivos[i]['op']){
        this.principaisLista.push({
          codigo: this.globalVars.listaAtivos[i]['codigo'],
          nome: this.globalVars.listaAtivos[i]['nome'],
        });
      }
    }
  }

  ionViewDidEnter(){
    this.storage.get('opcoesRecenteList').then((val) => {
      if(val != null){
        this.recentesLista = val;
      }
      this.isRecentesLoaded = true;

      if(this.listParams.ticker != ''){
        setTimeout(() => {
          this.globalVars.buscaMercadoOpcoesCodigo.codigo = this.listParams.ticker;
          this.nomeAtivo = this.globalVars.buscaMercadoOpcoesCodigo.nome = this.listParams.nome;
          this.prepareGetOpcoesList(-1, '');
        }, 1000);
      }
    
      if(!this.globalVars.isUserLogged){
        this.globalVars.showAlertInfo('Não conectado', 'Você não parece estar logado e por isto, algumas funções podem não funcionar!');
      }
    });
  }

  onResize() {
    const isSafari = this.globalVars.getUserAgent('Safari');
    if(isSafari){
      this.td_opcoes = window.outerWidth - this.td_head - 45; //ultimo e padding
      return false;
    }

    this.td_opcoes = window.innerWidth - this.td_head - 45; //ultimo e padding
  }

  ionSlideDidChange(){
    this.slider.getActiveIndex().then(index => {
      this.currentSlide = index;
      if(index == 1){
        this.getOpcoesList(this.selectedIndex, this.opcaoCategoria);
      }
    });
  }

  updateWithFilter(){
    this.getOpcoesList(this.selectedIndex, this.opcaoCategoria);
  }

  getSelectFilter(action){
    switch(action){
      case 'vencimento' :
      this.vencimento = this.vencimentoSelect.value;
      this.inicioStrikeSelected = '';
      this.fimStrikeSelected = '';
      break;

      case 'tipo' :
      this.tipoSelected = this.tipoSelect.value;
      break;

      case 'estilo' :
      this.estiloSelected = this.estiloSelect.value;
      break;

      case 'inicioStrike' :
      this.opcoesList['inicioStrikeSelected'] = this.inicioStrikeSelect.value;
      this.inicioStrikeSelected = this.inicioStrikeSelect.value;
      break;

      case 'fimStrike' :
      this.opcoesList['fimStrikeSelected'] = this.fimStrikeSelect.value;
      this.fimStrikeSelected = this.fimStrikeSelect.value;
      break;
    };

    this.updateButtonDisabled = false;
  }

  prepareGetOpcoesList(index, categoria){
    this.selectedIndex = index;
    this.opcaoCategoria = categoria;
    this.showBackButton = true;
    this.opcoesDadosLoaded = false;
    this.cotacaoPrincipalLoaded = false;
    this.vencimento = '';
    this.inicioStrikeSelected = '';
    this.fimStrikeSelected = '';
    this.tipoSelected = '';
    this.estiloSelected = '';
    
    this.codigoAtivo = this.globalVars.buscaMercadoOpcoesCodigo.codigo;
    this.nomeAtivo = this.globalVars.buscaMercadoOpcoesCodigo.nome;
    if(this.selectedIndex > -1){
      if(categoria == 'principal'){
        this.codigoAtivo =  this.principaisLista[this.selectedIndex]['codigo'];
        this.nomeAtivo = this.principaisLista[this.selectedIndex]['nome'];
      }else{
        this.codigoAtivo = this.recentesLista[this.selectedIndex]['codigo'];
        this.nomeAtivo = this.recentesLista[this.selectedIndex]['nome'];
        this.tipoSelected  = this.recentesLista[this.selectedIndex]['tipo'];
        this.estiloSelected = this.recentesLista[this.selectedIndex]['estilo'];
        this.vencimento = this.recentesLista[this.selectedIndex]['vencimento'];
        this.inicioStrikeSelected = this.recentesLista[this.selectedIndex]['inicioStrike'];
        this.fimStrikeSelected = this.recentesLista[this.selectedIndex]['fimStrike'];
      }
    }
    this.slider.slideTo(1);
  }

  getOpcoesList(index, categoria){
    clearTimeout(this.timeoutAtualizaCotacoes);

    this.selectedIndex = index;
    this.opcaoCategoria = categoria;
    this.showBackButton = true;
    this.opcoesDadosLoaded = false;

    this.http.get(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/opcoes/getOpcoesList&codigo=' + this.codigoAtivo + '&vencimento=' + this.vencimento + '&inicioStrike=' + this.inicioStrikeSelected + '&fimStrike=' + this.fimStrikeSelected + '&tipo=' + this.tipoSelected + '&estilo=' + this.estiloSelected + '&session='+this.globalVars.appConfig.session).subscribe(
      data =>{
        this.opcoesList = data;
        this.opcoesListOriginal = JSON.parse(JSON.stringify(this.opcoesList));
        this.inicioStrikeSelected  = this.opcoesList['inicioStrikeSelected'];
        this.fimStrikeSelected  = this.opcoesList['fimStrikeSelected'];
        this.tipoSelected = this.opcoesList['tipoSelected'];
        this.estiloSelected = this.opcoesList['estiloSelected'];
        this.vencimento = this.opcoesList['vencimentoSelected'];
        this.updateButtonDisabled = true;
        this.cotacaoPrincipalLoaded = true;
        this.opcoesDadosLoaded = true;
        this.saveAcessoRecenteList();

        this.timeoutAtualizaCotacoes = setTimeout(() => {
          this.atualizaOpcoesCotacao();
        }, 30000);
      },
      error => {
        setTimeout(() => {
          this.getOpcoesList(this.selectedIndex, this.opcaoCategoria);
        }, 10000);
      }
    );
  }

  atualizaOpcoesCotacao(){
    let ativosList = [];
    Object.keys(this.opcoesList['cotacao']).forEach((key) => {
      ativosList.push({
        codigo: key,
        strike: this.opcoesList['cotacao'][key]['strike'],
        tipo: this.opcoesList['cotacao'][key]['tipo'],
      });
    });

    let headers = new HttpHeaders({
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    });

    let postFields = {
      ativosList: ativosList,
     }

    this.http.post(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/opcoes/atualizaOpcoesCotacao&list=' + ativosList + '&session='+this.globalVars.appConfig.session, postFields, {headers: headers}).subscribe(
      data =>{
        let dataResult = data;
      
        Object.keys(dataResult['cotacoes']).forEach((key) => {
          this.opcoesList['cotacao'][key] = dataResult['cotacoes'][key];
        });

        this.timeoutAtualizaCotacoes = setTimeout(() => {
          this.atualizaOpcoesCotacao();
        }, 30000);
      },
      error => {
        clearTimeout(this.timeoutAtualizaCotacoes);
        this.timeoutAtualizaCotacoes = setTimeout(() => {
          this.atualizaOpcoesCotacao();
        }, 10000);
      }
    );
  }

  async openGrafico(ticker, tipoOperacao){
    if(this.isModalGraficoOpen == false){
      this.isModalGraficoOpen = true;

      const modalGrafico = this.modalCtrl.create({
        component: GraficoPage,
        componentProps: {ticker: ticker, tipoOperacao: tipoOperacao},
      });

      this.renderer.listen((await modalGrafico), 'ionModalWillDismiss', () => {
        this.isModalGraficoOpen = false;
      });

      (await modalGrafico).present();
    }  
  }

  showCotacaoInfo(i, g){
    let codigo = this.opcoesList['opcoesList'][0]['tbody'][g];
    this.globalVars.showAlertInfo('Cotação ' + codigo, '<span class="' + this.opcoesList['cotacao'][codigo]['styleVariacao'] + '">' + this.opcoesList['cotacao'][codigo]['cotacao'] + ' | <small>' + this.opcoesList['cotacao'][codigo]['variacao_valor'] + ' (' + this.opcoesList['cotacao'][codigo]['variacao'] + '%)</small></span><br /><small>Min. ' + this.opcoesList['cotacao'][codigo]['min'] + ' | Max. ' + this.opcoesList['cotacao'][codigo]['max'] + '</small><br /><small>' + this.opcoesList['cotacao'][codigo]['pregao'] + '</small>');
  }

  backMainPage(){
    clearTimeout(this.timeoutAtualizaCotacoes); 
    if(this.opcoesDadosLoaded){
      this.saveAcessoRecenteList();
    }     
    this.showBackButton = false;
    this.slider.slideTo(0);
    setTimeout(() => {
      //this.opcoesList = [];
      this.opcoesDadosLoaded = false;
    }, 1000);
  }

  saveAcessoRecenteList(){
    let i;
    let recenteFoundIndex = -1;
    
    for(i = 0; i < this.recentesLista.length; i++){
      if(this.recentesLista[i]['codigo'] == this.codigoAtivo && recenteFoundIndex == -1){
        recenteFoundIndex = i;
      }
    }

    //tem que ser slice. Delete mantem o length e gera erro no ngFor do html
    if(recenteFoundIndex > -1){
      this.recentesLista.splice(recenteFoundIndex,1);
    }

    if(this.recentesLista.length == 12){
      this.recentesLista.splice(this.recentesLista.length-1,1);
    }

    this.recentesLista.unshift({
      codigo: this.codigoAtivo,
      nome: this.nomeAtivo,
      tipo: this.tipoSelected,
      estilo: this.estiloSelected,
      vencimento: this.vencimento,
      inicioStrike: this.inicioStrikeSelected,
      fimStrike: this.fimStrikeSelected,
    });

    this.storage.set('opcoesRecenteList', this.recentesLista);
  }

  async openBusca(action){
    if(this.isModalBuscaOpen == false){
      this.isModalBuscaOpen = true;

      const modalBusca = this.modalCtrl.create({
        component: BuscaPage,
        componentProps: {
          action: action,
          index_mercado_opcoes: 1,
        },
      });

      this.renderer.listen((await modalBusca), 'ionModalDidDismiss', () => {
        this.isModalBuscaOpen = false;
        if(this.globalVars.buscaMercadoOpcoesCodigo.codigo != ''){
          this.prepareGetOpcoesList(-1, '');
        }
      });

      (await modalBusca).present();
    }
  }

  closeOpcoesModal(){
    clearTimeout(this.timeoutAtualizaCotacoes);
    if(this.currentSlide == 1 && this.opcoesDadosLoaded){
      this.saveAcessoRecenteList();
    }
    this.modalCtrl.dismiss();
  }
}
