import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { GlobalVars } from './service';
import { AumentaPosicaoPage } from './aumenta-posicao/aumenta-posicao.page';
import { ProventosAluguelPage } from './proventos-aluguel/proventos-aluguel.page';
import { ResgatePage } from './resgate/resgate.page';
import { GraficoPage } from './grafico/grafico.page';
import { AtivoExtraActions } from './service_ExtraActions';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    GlobalVars, AtivoExtraActions, AumentaPosicaoPage, ProventosAluguelPage, GraficoPage, ResgatePage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
