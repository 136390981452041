<ion-header>
  <ion-toolbar>
    <ion-title>Buscar {{action}}</ion-title>
     <ion-buttons slot="end">
      <ion-button (click)="closeBuscaModal()">Fechar</ion-button>
    </ion-buttons>
   </ion-toolbar>
   <ion-toolbar>
     <ion-searchbar (ionChange)="ionChangeSearch($event)" [(ngModel)]="lastSearch" id="input_busca" placeholder="Buscar"></ion-searchbar>
    </ion-toolbar>
 </ion-header>

 <ion-content #contentBusca [fullscreen]="true">
  <div *ngIf="!globalVars.isUserLogged" style="text-align: center; padding-top: 50px;">
    <ion-text color="medium">
      <ion-icon name="sad-outline" style="font-size:60px;"></ion-icon>
      <p>Você não está logado!</p>
    </ion-text>
  </div>
  <ion-item *ngFor="let result of searchResult" (click)="selectRowSearchResult(result['codigo'], result['nome'])" button lines="full" mode="md">
    <ion-checkbox *ngIf="index_mercado != -1" [checked]="result['radar_show'] ? 'true' : 'false'" (ionChange)="toggleAtivoRadar(result)" mode="md" slot="start" style="margin-right: 18px;"></ion-checkbox>  
    <ion-avatar slot="start"><img class="{{(result['codigo'] | slice:0:4)}}" src="http://instabov.images.xtremebh.com.br/ticker-empty.png" /></ion-avatar>
    {{result["nome"]}} <small class="small">{{result["small"]}}</small>
  </ion-item>
</ion-content>