import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { GlobalVars } from './service';
import { OpcoesPage } from './opcoes/opcoes.page';
import { GraficoPage } from './grafico/grafico.page';

@Injectable()
export class AtivoExtraActions{
    private unlistnerActionSheetDidDismis: () => void;
    private unlistnerIonModalWillDismiss: () => void;
    isActionSheetOpen = false;
    isModalOpen = false;

    private renderer: Renderer2;

    constructor(private globalVars: GlobalVars, private rendererFactory: RendererFactory2, private modalCtrl: ModalController, private actionSheetController: ActionSheetController){
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    async showExtraActions(index, event){
        event.stopImmediatePropagation();
        
        let ticker = this.globalVars.carteira[index]['codigo'];
        let empresaTicker = this.globalVars.carteira[index]['empresa'];
        let empresaNome = this.globalVars.carteira[index]['nome'];
        let tipoOperacao = this.globalVars.carteira[index]['tipo_operacao'];
        
        let buttons = [{
          text: 'Fechar',
          icon: 'close-outline',
          handler: () => {
          }
        },{
          text: 'Mercado de Opções',
          icon: 'options-outline',
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => { this.openOpcoesModal(empresaTicker, empresaNome); }, 600);
            return false;
          }
        },{
          text: 'Gráfico ' + ticker,
          icon: 'bar-chart',
          handler: () => {
            setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
            setTimeout(() => { this.openGrafico(ticker, tipoOperacao) }, 700);
            return false;
          }
        }];
    
        const tickersList = this.globalVars.carteira[index]['tickers'];
    
        for(let i = 0; i < tickersList.length; i++){
          if(ticker != tickersList[i]){
            buttons.push({
              text: 'Gráfico ' + tickersList[i],
              icon: 'bar-chart',
              handler: () => {
                setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
                setTimeout(() => { this.openGrafico(tickersList[i], 'Normal') }, 700);
                return false;
              }
            });
          }
        }
           
        const actionSheetExtraActions = this.actionSheetController.create({
          header: 'Escolha uma opção',
          mode: 'md',
          buttons: buttons,
        });
    
        if(!this.isActionSheetOpen){
          this.isActionSheetOpen = true;
          this.unlistnerActionSheetDidDismis = this.renderer.listen((await actionSheetExtraActions), 'ionActionSheetDidDismiss', () => {
            this.isActionSheetOpen = false;
            this.unlistnerActionSheetDidDismis();
          });
    
          (await actionSheetExtraActions).present();
        }
    }

    async openOpcoesModal(ticker, nome){
        if(this.isModalOpen == false){
          this.isModalOpen = true;
    
          const modalOpcoes = this.modalCtrl.create({
            component: OpcoesPage,
            componentProps: {ticker: ticker, nome: nome},
          });
    
          this.unlistnerIonModalWillDismiss = this.renderer.listen((await modalOpcoes), 'ionModalWillDismiss', () => {
            this.isModalOpen = false;
            this.unlistnerIonModalWillDismiss();
          });
    
          (await modalOpcoes).present();
        }  
      }
    
      async openGrafico(ticker, tipoOperacao){
        if(this.isModalOpen == false){
          this.isModalOpen = true;
    
          const modalGrafico = this.modalCtrl.create({
            component: GraficoPage,
            componentProps: {ticker: ticker, tipoOperacao: tipoOperacao},
          });
    
          this.unlistnerIonModalWillDismiss = this.renderer.listen((await modalGrafico), 'ionModalWillDismiss', () => {
            this.isModalOpen = false;
          });
    
          (await modalGrafico).present();
        }  
    }
}