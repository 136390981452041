import { Component, Renderer2 } from '@angular/core';
import { GlobalVars } from '../service';
import { ModalController, NavParams, } from '@ionic/angular';

declare let $;

@Component({
  selector: 'app-busca',
  templateUrl: './busca.page.html',
  styleUrls: ['./busca.page.scss'],
})
export class BuscaPage {
  action;
  index_mercado = -1;
  index_mercado_opcoes = -1;
  searchResult;
  lastSearch = '';
  isSearching = false;
  needUpdateAtivo = false;
  
  constructor(public globalVars: GlobalVars, private renderer: Renderer2, private modalCtrl: ModalController, private navParams: NavParams) {
    this.action = navParams.get('action');
    this.index_mercado = (navParams.get('index_mercado') ? navParams.get('index_mercado') : -1);
    this.index_mercado_opcoes = (navParams.get('index_mercado_opcoes') ? navParams.get('index_mercado_opcoes') : -1);
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.action == 'Corretora' && this.globalVars.appConfig.lastCorretoraSearch != ''){
        this.lastSearch = this.globalVars.appConfig.lastCorretoraSearch;
      }else if(this.action == 'Ativo' && this.globalVars.appConfig.lastAtivoSearch != '' && this.index_mercado == -1){
        this.lastSearch = this.globalVars.appConfig.lastAtivoSearch;
      }else{
        this.lastSearch = '';
      }
    }, 500);
  }

  ionChangeSearch(button){
    if(this.isSearching || !this.globalVars.isUserLogged){
      return false;
    }

    requestAnimationFrame(() => {
      let myResult = [];
      let array_index = -1;
      let mercado_index = this.index_mercado;
      let radarAtivosList = this.globalVars.radarAtivosList;

      if(button.target.value.length >= 3){
        if(this.action == 'Corretora'){
          this.globalVars.listaCorretoras.forEach(item => { 
            if(item['nome'].toLowerCase().includes(button.target.value.toLowerCase())){
              myResult.push({
                nome: item['nome'],
                codigo: item['cnpj'],
                small: ''
              });
            }
          });
        }else{
          this.globalVars.listaAtivos.forEach(item => {
            if(item['nome'].toLowerCase().includes(button.target.value.toLowerCase()) || item['codigo'].toLowerCase().includes(button.target.value.toLowerCase())){
              if(mercado_index > -1){
                //quando for busca para o radar, precisamos verificar se existe na lista ja selecionada
                array_index = radarAtivosList.indexOf(item['codigo']);
                if( array_index > -1){
                  return false;
                }
              }
              
              myResult.push({
                nome: item['nome'],
                codigo: item['codigo'],
                small: '(' + item['codigo'] + ')',
                radar_show: (array_index > -1 ? true : false),
                mercado_opcoes: (array_index > -1 ? true : false),
              });
            }
          });
        }
      }else if(button.target.value == ''){
        myResult = [];
      }

      this.lastSearch = button.target.value;
      this.searchResult = myResult;
      this.isSearching = false;
    });
  }

  selectRowSearchResult(codigo, nome){
    if(this.action == 'Corretora'){
      $('#input_corretora').val(nome);
      $('#codigo_corretora').val(codigo);
      this.globalVars.appConfig.lastCorretoraSearch = this.lastSearch;
    }else if(this.index_mercado == -1 && this.index_mercado_opcoes == -1){
      $('#input_ativo').val(nome);
      $('#codigo_empresa').val(codigo);
      this.globalVars.setColorChart = codigo;
      this.globalVars.appConfig.lastAtivoSearch = this.lastSearch;

      if( $("#input_opcao_codigo").val().indexOf(codigo) < 0){
        $('#input_opcao_codigo').val( $('#codigo_empresa').val().substr(0, 4) );
      }
    }else if(this.index_mercado > -1){
      //aqui selecionando ativo para radar mercado
      for(let i = 0; i < this.searchResult.length; i++){
        if(this.searchResult[i]['codigo'] == codigo){
          this.searchResult[i]['radar_show'] = (this.searchResult[i]['radar_show'] ? false : true);
        }
      }
    }else{
      //aqui selecionado ativo para mercado opcoes
      this.globalVars.buscaMercadoOpcoesCodigo.codigo = codigo;
      this.globalVars.buscaMercadoOpcoesCodigo.nome = nome;
    }

    if(this.index_mercado == -1){
      this.globalVars.saveAppConfig();
      this.needUpdateAtivo = true;
      setTimeout(() => {
        this.closeBuscaModal();
      }, 400);
    }
  }

  toggleAtivoRadar(result){
    let array_index = this.globalVars.radarAtivosList.indexOf(result['codigo']);

    if(array_index > -1){
      //aqui indica que temos que remover da lista
      this.globalVars.radarAtivosList.splice(array_index, 1);
      this.globalVars.radarAtivosNomesList.splice(array_index, 1);
    }else{
      this.globalVars.radarAtivosList.push(result['codigo']);
      this.globalVars.radarAtivosNomesList.push(result['nome']);
    }
    this.globalVars.doSaveRadarAtivos = true;
  }
  
  closeBuscaModal(){
    if(this.index_mercado > -1 && this.globalVars.doSaveRadarAtivos == true){
      //sempre vamos zerar a categoria, pois quando a busca abre, ele carrega para o radarAtivosList todos que ja existem
      //fazendo isto, consigo remover os que o usuario pediu para tirar
      this.globalVars.mercados[this.index_mercado]['categorias'] = [];
      for(let i=0; i < this.globalVars.radarAtivosList.length; i++){
        this.globalVars.mercados[this.index_mercado]['categorias'].push({
          id: this.globalVars.radarAtivosList[i],//id vai ser tambem o codigo do ativo
          codigo: this.globalVars.radarAtivosList[i],
          titulo: this.globalVars.radarAtivosNomesList[i],
          logo: 'https://www.xtremebh.com.br/image/bovespa/empresas/' + this.globalVars.radarAtivosList[i].substr(0,4) + '.png',
          cotacao: '---',
          min: '---',
          max: '---',
          volume: '---',
          variacao: '---',
          variacao_percent: '---',
          fonte: 'Fonte B3',
          hasLoaded: true,
          show: true,
          show_old: true,
        });
      }
    }

    if(this.lastSearch != ''){
      switch(this.action){
        case 'Corretora' :
        this.globalVars.appConfig.lastCorretoraSearch = this.lastSearch;
        break;

        case 'Ativo' :
          this.globalVars.appConfig.lastAtivoSearch = this.lastSearch;
        break;
      }

      this.globalVars.saveAppConfig();
    }
    
    this.modalCtrl.dismiss({needUpdateAtivo: this.needUpdateAtivo});
  }
}