import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActionSheetController, ModalController, NavParams } from '@ionic/angular';
import { createChart } from 'lightweight-charts';
import { GlobalVars } from '../service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.page.html',
  styleUrls: ['./grafico.page.scss'],
})
export class GraficoPage implements OnInit {
  //@ViewChild('graficoDiv') graficoDiv: ElementRef<HTMLInputElement>;
  showPoweredBy = true;
  ticker;
  tipoOperacao = '';
  tradingViewWidget: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');
  //innerWidth = 0;
  //innerHeight = 0;

  iframeWidth = 0;
  iframeHeight = 0;
  iframeMarginLeft = 0;
  iframePaddingTop = 44;

  isWideScreen = false;

  isActionSheetConfigOpen = false;

  container;

  dataResultGrafico:any;

  greenColor = 'rgb(38, 166, 154)';
  redColor = 'rgb(239, 83, 80)';
  volumeGreenColor = 'rgba(38, 166, 154, 0.5)';
  volumeRedColor = 'rgba(239, 83, 80, 0.5)';

  navParams = new NavParams();
  
  constructor(public globalVars: GlobalVars, private sanitizer:DomSanitizer, private http: HttpClient, private renderer: Renderer2, private actionSheetController: ActionSheetController, private modalCtrl: ModalController) {
    this.ticker = this.navParams.get('ticker');
    this.tipoOperacao = this.navParams.get('tipoOperacao');
  }

  ngOnInit() {
    //this.innerWidth = window.innerWidth; 
    //this.innerHeight = window.innerHeight;
    //alert(this.innerWidth)

    this.iframeWidth = window.innerWidth;
    this.iframeHeight = window.innerHeight+3;

    const theme = (this.globalVars.appConfig.darkMode ? 'dark' : 'light');

    this.setGraficoOrientation();

    setTimeout(() => {
      this.showPoweredBy = false;
    }, 3000);

    if(this.tipoOperacao != 'Opção' && this.tipoOperacao != 'Opção Lançamento'){
      this.tradingViewWidget = this.sanitizer.bypassSecurityTrustResourceUrl(this.globalVars.URL_DOMAIN + '/image/bovespa/tradingview_grafico.php?codigo='+this.ticker+'&theme='+theme);
      return;
    }

    this.tradingViewWidget = '';

    this.http.get(this.globalVars.URL_DOMAIN + 'index.php?route=finance_v2/cotacoes/getDadosGrafico&codigo=' + this.ticker + '&tipoOperacao=' + this.tipoOperacao + '&session='+this.globalVars.appConfig.session).subscribe(
      data =>{
        this.dataResultGrafico = data;
        this.createGrafico();
      },
      error => {
        this.globalVars.showAlertInfo('Erro', 'Ocorreu um erro ao tentar carregar o gráfico! Tente novamente ou entre em contato com nossa equipe.')
      }
    );
  }

  ionViewDidEnter(){
    const ionToolBar = document.getElementById('ionToolBar');
    this.iframePaddingTop = ionToolBar.clientHeight
  }

  createGrafico(){
    if(this.dataResultGrafico.length == 0){
      this.globalVars.showAlertInfo('Não disponível', 'Infelizmente este gráfico não está mais disponível!');
      return false;
    }

    this.container = document.getElementById('graficoDiv');
    this.container.innerHTML = '';

    let chartWidth = this.container.offsetWidth;
    let chartHeight = this.container.offsetHeight;
        
    const grafico = createChart(this.container, { 
      width: this.container.offsetWidth, //chartWidth,
      height: this.container.offsetHeight-20, //chartHeight,
      localization: {
        locale: 'pt-BR',
      },
      layout: {
        backgroundColor: (this.globalVars.appConfig.darkMode ? '#222' : 'white'),
        textColor: (this.globalVars.appConfig.darkMode ? '#999' : '#222'),
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      
      crosshair: {
        //mode: LightweightCharts.CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
     });
        
    var candleSeries = grafico.addCandlestickSeries({
      upColor: this.greenColor,
      downColor: this.redColor,
      borderDownColor: this.redColor,
      borderUpColor: this.greenColor,
      wickDownColor: this.redColor,
      wickUpColor: this.greenColor,
    });

    var volumeSeries = grafico.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    
    let i;
    let volumeData = [];
    let candlesData = [];

    //suspeito que o adfvn retornar o maximo de 30 dias e acima disto, retorna undefined
    const loopTotal = this.dataResultGrafico['c'].length > 30 ? 30 : this.dataResultGrafico['c'].length;
  
    for(let i=0; i < loopTotal; i++){
      volumeData.push({
        time: this.dataResultGrafico['t'][i],
        value: this.dataResultGrafico['v'][i],
        color: (this.dataResultGrafico['o'][i] > this.dataResultGrafico['c'][i] ? this.volumeGreenColor : this.volumeRedColor),
      });

      candlesData.push({
        time: this.dataResultGrafico['t'][i],
        open: this.dataResultGrafico['o'][i],
        high: this.dataResultGrafico['h'][i],
        low: this.dataResultGrafico['l'][i],
        close: this.dataResultGrafico['c'][i],
      });
    } 
    
    candleSeries.setData(candlesData);
    volumeSeries.setData(volumeData);    

    let graficoCotacao = document.getElementById('graficoCotacao');
    let graficoDados = document.getElementById('graficoDados');
    let resultGrafico = this.dataResultGrafico;
    let timeGrafico;
    let dataBR;
    let varStyle = '';

    grafico.subscribeCrosshairMove(function(param) {
      if (!param.time || param.point.x < 0 || param.point.x > chartWidth || param.point.y < 0 || param.point.y > chartHeight) {
        return;
      }

      //dadosGrafico = param.seriesPrices.get(candleSeries);
    
      timeGrafico = param.time['year'] + '-' + String("0" + param.time['month']).slice(-2) + '-' + String("0" + param.time['day']).slice(-2);
      dataBR = String("0" + param.time['day']).slice(-2) + '/' + String("0" + param.time['month']).slice(-2) + '/' + param.time['year'];

      for(i = 0; i < resultGrafico['t'].length; i++){
        if(resultGrafico['t'][i] == timeGrafico){
          graficoDados.innerHTML = 'Abr.: ' + resultGrafico['o_M'][i] + ' | Máx.: ' + resultGrafico['h_M'][i] + ' | Min.: ' + resultGrafico['l_M'][i] + ' | Vol.:' + resultGrafico['v_M'][i] + '<br />';
          
          varStyle = (resultGrafico['var'][i] > 0 ? 'style="color: #090"' : '');
          varStyle = (resultGrafico['var'][i] < 0 ? 'style="color: #F00"' : '');

          graficoCotacao.innerHTML = '<span ' + varStyle + '>' + resultGrafico['c_M'][i] + ' | </span><small ' + varStyle + '>' + (resultGrafico['var_M'][i] != undefined ? resultGrafico['var_M'][i] + ' (' + resultGrafico['var_per'][i]  + '%)' : '') + '</small>' + '<small> | ' + dataBR + '</small>';
        }
      }
    });
    /*
    // update tooltip
    grafico.subscribeCrosshairMove(function(param) {
      if (!param.time || param.point.x < 0 || param.point.x > chartWidth || param.point.y < 0 || param.point.y > chartHeight) {
        toolTip.style.display = 'none';
        return;
      }

      //var dateStr = LightweightCharts.isBusinessDay(param.time)
        //? businessDayToString(param.time)
        //: new Date(param.time * 1000).toLocaleDateString();

      toolTip.style.display = 'block';
      var price:any = param.seriesPrices.get(candleSeries);
      toolTip.innerHTML = '<div style="color: rgba(255, 70, 70, 1)">' + this.ticker + '</div>' +
        '<div style="font-size: 24px; margin: 4px 0px">' + Math.round(price * 100) / 100 + '</div>' +
        '<div>' + 'dateStr' + '</div>';

      var y = param.point.y;

      var left = param.point.x + toolTipMargin;
      if (left > chartWidth - toolTipWidth) {
        left = param.point.x - toolTipMargin - toolTipWidth;
      }

      var top = y + toolTipMargin;
      if (top > chartHeight - toolTipHeight) {
        top = y - toolTipHeight - toolTipMargin;
      }

      toolTip.style.left = left + 'px';
      toolTip.style.top = top + 'px';
    });
    */
  }

  async configGrafico(){
    let oldOrientation = this.globalVars.appConfig.chartOrientation;

    const actionSheetExtraActions = this.actionSheetController.create({
      header: 'Configurações',
      mode: 'md',
      buttons: [{
        text: (oldOrientation == 'Retrato' ? 'Paisagem' : 'Retrato'),
        icon: (oldOrientation == 'Retrato' ? 'phone-landscape-outline' : 'phone-portrait-outline'),
        handler: () => {
          setTimeout(() => { this.actionSheetController.dismiss(); }, 400);
          setTimeout(() => {
            this.globalVars.appConfig.chartOrientation = (oldOrientation == 'Retrato' ? 'Paisagem' : 'Retrato');
            this.globalVars.saveAppConfig();
            this.setGraficoOrientation();
          }, 700);
          return false;
        }
      }],
    });

    if(this.isActionSheetConfigOpen == false){
      this.isActionSheetConfigOpen = true;
      this.renderer.listen((await actionSheetExtraActions), 'ionActionSheetDidDismiss', () => {
        this.isActionSheetConfigOpen = false;
      });

      (await actionSheetExtraActions).present();
    }
  }

  onResize() {
    /*
    if(window.innerWidth > window.innerHeight){
      this.globalVars.appConfig.chartOrientation = 'Paisagem';
    }else{
      this.globalVars.appConfig.chartOrientation = 'Retrato';
    }

    this.graficoPage.setGraficoOrientation();
    */

    this.setGraficoOrientation();
  }

  setGraficoOrientation(){
    const orietation:any = '';//this.globalVars.appConfig.chartOrientation;

    const isSafari = this.globalVars.getUserAgent('Safari');

    //PWA no ios, quando muda a orientacao, o safari automaticamente inverte as medidas
    if(orietation == 'Paisagem'){
      //this.isWideScreen = true;
      this.iframeWidth = isSafari ? window.outerWidth : window.innerWidth;
      this.iframeHeight = isSafari ? window.outerHeight : window.innerHeight;
      //this.iframeMarginLeft = window.outerWidth - 4;
      (this.tradingViewWidget == '' ? setTimeout(() => { alert('createGrafico'); this.createGrafico() }, 200) : '');
      return false;
    }

    this.isWideScreen = false;
    this.iframeWidth = isSafari ? window.outerWidth : window.innerWidth;
    this.iframeHeight = (isSafari ? window.outerHeight : window.innerHeight) - 24;
    this.iframeMarginLeft = 0;
    (this.tradingViewWidget == '' ? setTimeout(() => { this.createGrafico() }, 200) : '');
  }

  closeGraficoModal(){
    setTimeout(() => {
      if(this.container){
        this.container.innerHTML = '';
      }
    }, 200);
    this.modalCtrl.dismiss();
  }
}
