import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'noticias',
    loadChildren: () => import('./noticias/noticias.module').then( m => m.NoticiasPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./config/config.module').then( m => m.ConfigPageModule)
  },
  {
    path: 'comunidade',
    loadChildren: () => import('./comunidade/comunidade.module').then( m => m.ComunidadePageModule)
  },
  {
    path: 'adicionar',
    loadChildren: () => import('./adicionar/adicionar.module').then( m => m.AdicionarPageModule)
  },
  {
    path: 'ajuda',
    loadChildren: () => import('./ajuda/ajuda.module').then( m => m.AjudaPageModule)
  },
  {
    path: 'busca',
    loadChildren: () => import('./busca/busca.module').then( m => m.BuscaPageModule)
  },
  {
    path: 'colorpicker',
    loadChildren: () => import('./colorpicker/colorpicker.module').then( m => m.ColorpickerPageModule)
  },
  {
    path: 'observacoes',
    loadChildren: () => import('./observacoes/observacoes.module').then( m => m.ObservacoesPageModule)
  },
  {
    path: 'ativoinfo',
    loadChildren: () => import('./ativoinfo/ativoinfo.module').then( m => m.AtivoinfoPageModule)
  },
  {
    path: 'aumenta-posicao',
    loadChildren: () => import('./aumenta-posicao/aumenta-posicao.module').then( m => m.AumentaPosicaoPageModule)
  },
  {
    path: 'proventos-aluguel',
    loadChildren: () => import('./proventos-aluguel/proventos-aluguel.module').then( m => m.ProventosAluguelPageModule)
  },
  {
    path: 'grafico',
    loadChildren: () => import('./grafico/grafico.module').then( m => m.GraficoPageModule)
  },
  {
    path: 'opcoes',
    loadChildren: () => import('./opcoes/opcoes.module').then( m => m.OpcoesPageModule)
  },
  {
    path: 'relatorio',
    loadChildren: () => import('./relatorio/relatorio.module').then( m => m.RelatorioPageModule)
  },
  {
    path: 'mercado',
    loadChildren: () => import('./mercado/mercado.module').then( m => m.MercadoPageModule)
  },
  {
    path: 'balanco-diario',
    loadChildren: () => import('./balanco-diario/balanco-diario.module').then( m => m.BalancoDiarioPageModule)
  },
  {
    path: 'balanco-historico',
    loadChildren: () => import('./balanco-historico/balanco-historico.module').then( m => m.BalancoHistoricoPageModule)
  },
  {
    path: 'resgate',
    loadChildren: () => import('./resgate/resgate.module').then( m => m.ResgatePageModule)
  },
  {
    path: 'finaliza-mes',
    loadChildren: () => import('./finaliza-mes/finaliza-mes.module').then( m => m.FinalizaMesPageModule)
  },
  {
    path: 'conta',
    loadChildren: () => import('./conta/conta.module').then( m => m.ContaPageModule)
  },
  {
    path: 'mercado-edit',
    loadChildren: () => import('./mercado-edit/mercado-edit.module').then( m => m.MercadoEditPageModule)
  },
  {
    path: 'mercado-categorias',
    loadChildren: () => import('./mercado-categorias/mercado-categorias.module').then( m => m.MercadoCategoriasPageModule)
  },
  {
    path: 'imposto-renda',
    loadChildren: () => import('./imposto-renda/imposto-renda.module').then( m => m.ImpostoRendaPageModule)
  },
  {
    path: 'imposto-detalhado',
    loadChildren: () => import('./imposto-detalhado/imposto-detalhado.module').then( m => m.ImpostoDetalhadoPageModule)
  },
  {
    path: 'mensagens',
    loadChildren: () => import('./mensagens/mensagens.module').then( m => m.MensagensPageModule)
  },
  {
    path: 'politicas-privacidade',
    loadChildren: () => import('./politicas-privacidade/politicas-privacidade.module').then( m => m.PoliticasPrivacidadePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'disabled', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
