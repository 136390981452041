<ion-header>
  <ion-toolbar id="ionToolBar">
     <ion-title>GRÁFICO {{ticker}}</ion-title>
    <ion-buttons slot="start" hidden>
      <ion-button (click)="configGrafico()"><i class="fas fa-cogs"></i></ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="closeGraficoModal()">Fechar</ion-button>
    </ion-buttons>
   </ion-toolbar>
 </ion-header>

<ion-content force-overscroll="false" (window:resize)="onResize()">
  
  <div *ngIf="showPoweredBy" class="loading-flex-container" style="z-index: 100">
    <div>
      Powered by<br /><br />
        <img src="../assets/imgs/tradingview_logo.png" width="50%" height="auto" />
    </div>
  </div>
  
  <!--GRAFICO DE OPACOES - PUXA OS DADOS DO ADVFN E MONTA NO GRAFICO TRADVIEW-->
  <div class="div-iframe" *ngIf="tradingViewWidget == ''" [ngClass]="{'widescreen':isWideScreen}" [style.margin-top.px]="iframePaddingTop" [style.margin-left.px]="iframeMarginLeft" [style.width.px]="iframeWidth" [style.height.px]="iframeHeight-30">
    <div id="graficoTooltip">
      <div id="graficoDados"></div>
      <div id="graficoCotacao"></div>
    </div>
    <div id="graficoDiv"></div>
  </div>

  <!--GRAFICO DE ATIVOS - PUXA JA PRONTO DO TRADVIEW-->
  <div class="div-iframe" *ngIf="tradingViewWidget != ''" [ngClass]="{'widescreen':isWideScreen}" [style.width.px]="iframeWidth" [style.height.px]="iframeHeight" >
    <iframe id="iframeTradingView" width="100%" height="100%" [src]="tradingViewWidget"></iframe>  
  </div>
</ion-content>
